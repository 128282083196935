import { useState } from 'react';
import AppointmentEditForm from '../../Forms/AppointmentEditForm/AppointmentEditForm';

import classes from './Appointment.module.css';

const Appointment = (props) => {
  const { data, filteredTypes } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const startTime = data.time.startTime.split('T')[1].slice(0, 5);
  const endTime = data.time.endTime.split('T')[1].slice(0, 5);
  let productType = data.productType;
  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_RENT') {
    productType = '소셜대기/대관가능';
  }

  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT') {
    productType = '소셜대기/대관불가';
  }
  const classList = `${classes.Appointment} ${
    !filteredTypes.includes(productType) ? classes.hidden : ''
  }`;

  const formOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  return (
    <li
      className={classList}
      data-product-type={productType}
      data-cancel-status={data.matchStatus}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
    >
      <div className={classes['Appointment__Inner']} onClick={formOpenHandler}>
        <span className={classes['Appointment__Time']}>
          <span className={classes['Appointment__StartTime']}>{startTime}</span>
          <span className={classes['Appointment__Time--Tilde']}>~</span>
          <span className={classes['Appointment__EndTime']}>{endTime}</span>
        </span>
        <span className={classes['Appointment__Consumer']}>
          {data.consumer}
        </span>
        {productType === '소셜매치' && data.matchApplyCnt !== undefined && (
          <span className={classes['Appointment__MoreInfo']}>
            {`${data.matchApplyCnt}/${data.maxPlayerCnt}`}
          </span>
        )}
      </div>
      {isFormOpen && (
        <AppointmentEditForm
          data={data}
          date={new Date(data.date)}
          onClose={formOpenHandler}
          {...props}
        />
      )}
    </li>
  );
};

export default Appointment;
