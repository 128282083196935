const PlabLogo = () => {
  return (
    <svg
      width='140'
      height='62'
      viewBox='0 0 140 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1052_2777)'>
        <path
          d='M7.26386 23.0566H69.1489L67.5015 31.4081H5.62142L7.26386 23.0566ZM12.5296 0.90625H72.5134L70.871 9.2577H64.2067L63.5348 12.7976H70.1792L68.6064 20.8085H8.61265L10.1854 12.7976H17.1931L17.885 9.2577H10.8822L12.5296 0.90625ZM6.09424 33.5861H66.083L62.5991 51.3754H14.1422L13.6445 53.8288H62.0815L60.4689 62H0.5L3.98396 44.2056H52.416L52.8838 41.8474H4.48167L6.09424 33.5861ZM30.3625 12.7976H50.3455L51.0423 9.2577H31.0643L30.3625 12.7976Z'
          fill='#1C1C1B'
        />
        <path
          d='M78.7199 0.906242H113.141L109.19 20.9687H86.3248L85.6828 24.2382H108.548L106.905 32.5897H72.4836L76.4354 12.5172H99.3001L99.9272 9.3378H77.0326L78.7199 0.906242ZM72.6677 36.2197H84.2195L83.5626 39.5542H120.154L120.816 36.1996H132.393L127.316 61.98H67.5961L72.6677 36.2197ZM80.9297 52.9226H117.521L118.382 48.5666H81.7957L80.9297 52.9226ZM126.918 10.224L120.05 32.5897H109.498L115.919 0H126.475L122.493 20.1476L128.944 0H139.5L133.085 32.5897H122.523L126.918 10.224Z'
          fill='#1C1C1B'
        />
      </g>
      <defs>
        <clipPath id='clip0_1052_2777'>
          <rect
            width='139'
            height='62'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlabLogo;
