import DayScaleEmptyCell from './DayScaleEmptyCell/DayScaleEmptyCell';
import DayScaleRow from './DayScaleRow/DayScaleRow';
import classes from './DayScaleLayout.module.css';

const DayScaleLayout = (props) => {
  const { schedulerState } = props;

  return (
    <div className={classes.DayScaleLayout}>
      {schedulerState.viewState === 'week' && <DayScaleEmptyCell />}
      <DayScaleRow {...props} />
    </div>
  );
};

export default DayScaleLayout;
