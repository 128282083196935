import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../../hooks/use-http';
import Loader from '../UI/Loader/Loader';
import classes from './Scheduler.module.css';

const getDaysOfMonth = (dateObj, firstDay = 'sun') => {
  let daysOfMonth = [];
  const firstDayOfMonth = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    1
  );

  if (firstDay === 'sun') {
    for (let i = 0; i < 42; i++) {
      daysOfMonth.push(
        new Date(
          firstDayOfMonth.getFullYear(),
          firstDayOfMonth.getMonth(),
          firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + i
        )
      );
    }
  }

  if (firstDay === 'mon') {
    if (firstDayOfMonth.getDate() === 1 && firstDayOfMonth.getDay() === 0) {
      for (let i = 0; i < 42; i++) {
        daysOfMonth.push(
          new Date(
            firstDayOfMonth.getFullYear(),
            firstDayOfMonth.getMonth(),
            firstDayOfMonth.getDate() - 6 + i
          )
        );
      }
    } else {
      for (let i = 0; i < 42; i++) {
        daysOfMonth.push(
          new Date(
            firstDayOfMonth.getFullYear(),
            firstDayOfMonth.getMonth(),
            firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + i + 1
          )
        );
      }
    }
  }

  return daysOfMonth;
};

const getDaysOfWeek = (dateObj, firstDay = 'sun') => {
  let daysOfWeek = [];
  const firstDayOfWeek = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate() - dateObj.getDay()
  );

  if (firstDay === 'sun') {
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(
        new Date(
          firstDayOfWeek.getFullYear(),
          firstDayOfWeek.getMonth(),
          firstDayOfWeek.getDate() + i
        )
      );
    }
  }

  if (firstDay === 'mon') {
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(
        new Date(
          firstDayOfWeek.getFullYear(),
          firstDayOfWeek.getMonth(),
          firstDayOfWeek.getDate() + i + 1
        )
      );
    }
  }

  return daysOfWeek;
};

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const schedulerDateReducer = (state, action) => {
  switch (action.type) {
    case 'PREV_MONTH':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date: getDaysOfMonth(
          new Date(
            state.date[6].getFullYear(),
            state.date[6].getMonth() - 1,
            1
          ),
          state.firstDayOfWeek
        ),
      };
    case 'NEXT_MONTH':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date: getDaysOfMonth(
          new Date(
            state.date[6].getFullYear(),
            state.date[6].getMonth() + 1,
            1
          ),
          state.firstDayOfWeek
        ),
      };
    case 'CUR_MONTH':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date: getDaysOfMonth(action.date, state.firstDayOfWeek),
      };
    case 'PREV_WEEK':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date: getDaysOfWeek(
          new Date(
            state.date[0].getFullYear(),
            state.date[0].getMonth(),
            state.date[0].getDate() - 7
          ),
          state.firstDayOfWeek
        ),
      };
    case 'NEXT_WEEK':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date: getDaysOfWeek(
          new Date(
            state.date[0].getFullYear(),
            state.date[0].getMonth(),
            state.date[0].getDate() + 7
          ),
          state.firstDayOfWeek
        ),
      };
    case 'CUR_WEEK':
      return {
        viewState: state.viewState,
        firstDayOfWeek: state.firstDayOfWeek,
        date:
          state.firstDayOfWeek === 'sun'
            ? getDaysOfWeek(action.date, state.firstDayOfWeek)
            : getDaysOfWeek(
                new Date(
                  action.date.getFullYear(),
                  action.date.getMonth(),
                  action.date.getDate() - 1
                ),
                state.firstDayOfWeek
              ),
      };
    case 'SWITCH_FIRST_DAY':
      if (state.viewState === 'month') {
        return {
          viewState: state.viewState,
          firstDayOfWeek: action.firstDayOfWeek,
          date: getDaysOfMonth(state.date[6], action.firstDayOfWeek),
        };
      }

      if (state.viewState === 'week') {
        return {
          viewState: state.viewState,
          firstDayOfWeek: action.firstDayOfWeek,
          date: getDaysOfWeek(state.date[0], action.firstDayOfWeek),
        };
      }
      return state;
    case 'SWITCH_VIEW':
      if (state.viewState === 'month') {
        if (state.date[0].getMonth() === state.date[6].getMonth()) {
          return {
            viewState: 'week',
            firstDayOfWeek: state.firstDayOfWeek,
            date: getDaysOfWeek(state.date[0], state.firstDayOfWeek),
          };
        }

        return {
          viewState: 'week',
          firstDayOfWeek: state.firstDayOfWeek,
          date: getDaysOfWeek(state.date[7], state.firstDayOfWeek),
        };
      }

      if (state.viewState === 'week') {
        return {
          viewState: 'month',
          firstDayOfWeek: state.firstDayOfWeek,
          date: getDaysOfMonth(state.date[0], state.firstDayOfWeek),
        };
      }
      return state;
    default:
      return state;
  }
};

const APPOINTMENT_TYPES = [
  '구장직접',
  '장기대관',
  '아카데미',
  '플랩대관',
  '소셜매치',
  '소셜대기/대관가능',
  '소셜대기/대관불가',
  '예약불가',
  '예약가능',
];

const Scheduler = (props) => {
  const params = useParams();
  const { isLoading, sendRequest: fetchAppointments } = useHttp();
  const today = new Date();
  const [schedulerState, dispatchSchedulerState] = useReducer(
    schedulerDateReducer,
    {
      viewState: 'week',
      firstDayOfWeek: 'mon',
      date: getDaysOfWeek(today, 'mon'),
    }
  );
  const [filteredTypes, setFilteredTypes] = useState(APPOINTMENT_TYPES);
  const [weeklyAppointmentList, setWeeklyAppointmentList] = useState([]);
  const [monthlyAppointmentList, setMonthlyAppointmentList] = useState([]);

  const changeFilteredTypesHandler = (selectedType) => {
    setFilteredTypes((prevState) => {
      if (prevState.includes(selectedType)) {
        return prevState.filter((type) => type !== selectedType);
      } else {
        return [...prevState, selectedType];
      }
    });
  };

  useEffect(() => {
    let startDate;
    let endDate;

    if (schedulerState.viewState === 'month') {
      startDate = adjDateFormat(schedulerState.date[0]);
      endDate = adjDateFormat(
        schedulerState.date[schedulerState.date.length - 1]
      );
    }

    if (schedulerState.viewState === 'week') {
      const schedulerLastDate =
        schedulerState.date[schedulerState.date.length - 1];

      startDate = adjDateFormat(
        new Date(
          schedulerState.date[0].getFullYear(),
          schedulerState.date[0].getMonth(),
          schedulerState.date[0].getDate() - 1
        )
      );
      endDate = adjDateFormat(
        new Date(
          schedulerLastDate.getFullYear(),
          schedulerLastDate.getMonth(),
          schedulerLastDate.getDate() + 1
        )
      );
    }

    const storeAppointments = async (res) => {
      const data = await res.json();

      if (res.ok) {
        if (schedulerState.viewState === 'month') {
          return setMonthlyAppointmentList(data.products);
        }
        if (schedulerState.viewState === 'week') {
          return setWeeklyAppointmentList(data.products);
        }
        return;
      }

      alert(data.message);
    };

    fetchAppointments(
      {
        urlPath: `products?startDate=${startDate}&endDate=${endDate}&zoneId=${params.zoneId}`,
      },
      storeAppointments
    );
  }, [
    params.zoneId,
    schedulerState.viewState,
    schedulerState.date,
    fetchAppointments,
  ]);

  const addPropsToReactElement = (element, props) => {
    if (React.isValidElement(element)) {
      return React.cloneElement(element, props);
    }
    return element;
  };

  const addPropsToChildren = (children, props) => {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props);
    }
    return children.map((childElement, idx) =>
      addPropsToReactElement(childElement, { key: idx, ...props })
    );
  };

  return (
    <>
      <div className={classes.Scheduler}>
        {addPropsToChildren(props.children, {
          adjDateFormat,
          today,
          schedulerState,
          onChangeSchedulerState: dispatchSchedulerState,
          filteredTypes: filteredTypes,
          onChangeFilteredTypes: changeFilteredTypesHandler,
          appointmentTypes: APPOINTMENT_TYPES,
          weeklyAppointmentList,
          monthlyAppointmentList,
        })}
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default Scheduler;
