import TimeTableRow from './TimeTableRow/TimeTableRow';
import classes from './TimeTableLayout.module.css';

const TimeTableLayout = (props) => {
  const {
    startDayHour,
    endDayHour,
    schedulerState,
    adjDateFormat,
    weeklyAppointmentList = [],
  } = props;

  const timeSlots = Array(endDayHour - startDayHour)
    .fill()
    .map((timeSlot, idx) =>
      schedulerState.date.map(
        (date) =>
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            startDayHour + idx
          )
      )
    );

  const filteredAppointments = Array(endDayHour - startDayHour)
    .fill()
    .map(() =>
      Array(7)
        .fill()
        .map(() => [])
    );

  for (let slotIdx in timeSlots) {
    for (let dayIdx in timeSlots[slotIdx]) {
      weeklyAppointmentList.forEach((appointment) => {
        const timeSlotDate = adjDateFormat(timeSlots[slotIdx][dayIdx]);
        const timeSlotHour = timeSlots[slotIdx][dayIdx].getHours();
        const startDateObj = new Date(appointment.time.startTime);
        const endDateObj = new Date(appointment.time.endTime);
        const tomorrowSixAM = new Date(
          startDateObj.getFullYear(),
          startDateObj.getMonth(),
          startDateObj.getDate() + 1,
          6,
          0
        );
        const todaySixAM = new Date(
          startDateObj.getFullYear(),
          startDateObj.getMonth(),
          startDateObj.getDate(),
          6,
          0
        );

        if (
          timeSlotDate === appointment.date &&
          timeSlotHour === startDateObj.getHours()
        ) {
          filteredAppointments[slotIdx][dayIdx].push({
            ...appointment,
            isOriginalOfSplittedBlock: true,
          });

          if (startDateObj.getHours() < 6 && endDateObj > todaySixAM) {
            const totalColumns = Math.ceil(
              (endDateObj - todaySixAM) / 1000 / 60 / 60 / 24
            );

            for (let i = 1; i <= totalColumns && +dayIdx + i < 7; i++) {
              let splittedAppointment;

              if (i !== totalColumns) {
                splittedAppointment = {
                  ...appointment,
                  splittedBlock: {
                    startTime: `${adjDateFormat(startDateObj)}T06:00`,
                    endTime: `${startDateObj.getFullYear()}-${
                      startDateObj.getMonth() < 9
                        ? `0${startDateObj.getMonth() + 1}`
                        : startDateObj.getMonth() + 1
                    }-${
                      startDateObj.getDate() < 10
                        ? `0${startDateObj.getDate() + 1}`
                        : startDateObj.getDate() + 1
                    }T06:00`,
                  },
                };
              } else {
                if (
                  endDateObj.getHours() >= 6 &&
                  endDateObj <= new Date(`${adjDateFormat(endDateObj)}T24:00`)
                ) {
                  splittedAppointment = {
                    ...appointment,
                    splittedBlock: {
                      startTime: `${adjDateFormat(endDateObj)}T06:00`,
                      endTime: appointment.time.endTime,
                    },
                    lastBlock: true,
                  };
                } else {
                  splittedAppointment = {
                    ...appointment,
                    splittedBlock: {
                      startTime: `${endDateObj.getFullYear()}-${
                        endDateObj.getMonth() < 9
                          ? `0${endDateObj.getMonth() + 1}`
                          : endDateObj.getMonth() + 1
                      }-${
                        endDateObj.getDate() - 1 < 10
                          ? `0${endDateObj.getDate() - 1}`
                          : endDateObj.getDate() - 1
                      }T06:00`,
                      endTime: appointment.time.endTime,
                    },
                    lastBlock: true,
                  };
                }
              }
              filteredAppointments[0][+dayIdx + i].push(splittedAppointment);
            }
          }

          if (startDateObj.getHours() >= 6 && endDateObj > tomorrowSixAM) {
            const totalColumns = Math.ceil(
              (endDateObj - tomorrowSixAM) / 1000 / 60 / 60 / 24
            );

            for (let i = 1; i <= totalColumns && +dayIdx + i < 7; i++) {
              let splittedAppointment;

              if (i !== totalColumns) {
                splittedAppointment = {
                  ...appointment,
                  splittedBlock: {
                    startTime: `${adjDateFormat(startDateObj)}T06:00`,
                    endTime: `${startDateObj.getFullYear()}-${
                      startDateObj.getMonth() < 9
                        ? `0${startDateObj.getMonth() + 1}`
                        : startDateObj.getMonth() + 1
                    }-${
                      startDateObj.getDate() < 10
                        ? `0${startDateObj.getDate() + 1}`
                        : startDateObj.getDate() + 1
                    }T06:00`,
                  },
                };
              } else {
                if (+appointment.time.endTime.split('T')[1].split(':')[0] < 6) {
                  splittedAppointment = {
                    ...appointment,
                    splittedBlock: {
                      startTime: `${endDateObj.getFullYear()}-${
                        endDateObj.getMonth() < 9
                          ? `0${endDateObj.getMonth() + 1}`
                          : endDateObj.getMonth() + 1
                      }-${
                        endDateObj.getDate() - 1 < 10
                          ? `0${endDateObj.getDate() - 1}`
                          : endDateObj.getDate() - 1
                      }T06:00`,
                      endTime: appointment.time.endTime,
                    },
                    lastBlock: true,
                  };
                } else {
                  splittedAppointment = {
                    ...appointment,
                    splittedBlock: {
                      startTime: `${adjDateFormat(endDateObj)}T06:00`,
                      endTime: appointment.time.endTime,
                    },
                    lastBlock: true,
                  };
                }
              }
              filteredAppointments[0][+dayIdx + i].push(splittedAppointment);
            }
          }
        }

        if (
          adjDateFormat(timeSlots[0][0]) === appointment.date &&
          startDateObj.getHours() < 6 &&
          endDateObj > todaySixAM
        ) {
          const splittedAppointment = {
            ...appointment,
            splittedBlock: {
              startTime: `${adjDateFormat(startDateObj)}T06:00`,
            },
          };
          filteredAppointments[0][0].push(splittedAppointment);
        }

        if (
          new Date(
            timeSlots[0][0].getFullYear(),
            timeSlots[0][0].getMonth(),
            timeSlots[0][0].getDate() - 1
          ).getDate() === +appointment.date.split('-')[2] &&
          endDateObj > timeSlots[0][0]
        ) {
          const splittedAppointment = {
            ...appointment,
            splittedBlock: {
              startTime: `${adjDateFormat(timeSlots[0][0])}T06:00`,
              endTime: appointment.time.endTime,
            },
            lastBlock: true,
          };
          filteredAppointments[0][0].push(splittedAppointment);
        }
      });
    }
  }

  const timeTableRows = Array(endDayHour - startDayHour)
    .fill()
    .map((row, idx) => (
      <TimeTableRow
        key={idx}
        {...props}
        timeSlot={timeSlots[idx]}
        appointments={filteredAppointments[idx]}
      />
    ));

  return <div className={classes.TimeTableLayout}>{timeTableRows}</div>;
};

export default TimeTableLayout;
