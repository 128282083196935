import { Fragment } from 'react';
import ReactDOM from 'react-dom';

import classes from './Loader.module.css';

const Backdrop = () => {
  return <div className={classes.Backdrop} />;
};

const Loader = () => {
  return ReactDOM.createPortal(
    <Fragment>
      <Backdrop />
      <div className={classes.Loader}></div>
    </Fragment>,
    document.getElementById('overlays')
  );
};

export default Loader;
