import classes from './TimeScaleLabel.module.css';

const TimeScaleLabel = (props) => {
  const { firstDayHour } = props;

  return (
    <div className={classes.TimeScaleLabel}>
      <div className={classes['TimeScaleLabel__time']}>
        {firstDayHour < 10 ? `0${firstDayHour}시` : `${firstDayHour}시`}
      </div>
    </div>
  );
};

export default TimeScaleLabel;
