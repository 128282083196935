import classes from './DayScaleCell.module.css';

const DayScaleCell = (props) => {
  const { schedulerState, date, today } = props;

  const isSunday = !date.getDay() ? classes.sunday : '';
  const isToday =
    schedulerState.viewState === 'week' &&
    date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) ===
      today.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      ? classes.today
      : '';
  const classList = `${classes.DayScaleCell} ${isSunday} ${isToday}`;

  return (
    <div className={classList}>
      <div className={classes['DayScaleCell__Inner']}>
        {schedulerState.viewState === 'month' &&
          date.toLocaleDateString('ko-KR', { weekday: 'short' })}
        {schedulerState.viewState === 'week' &&
          date
            .toLocaleDateString('ko-KR', { day: 'numeric', weekday: 'short' })
            .replace('일 ', ' ')}
      </div>
    </div>
  );
};

export default DayScaleCell;
