import Bars from '../../../UI/Icons/Bars/Bars';
import classes from './HamburgerBtn.module.css';

const HamburgerBtn = (props) => {
  const { isHeaderOpen, onClick } = props;

  return (
    <button
      type='button'
      className={`${classes.HamburgerBtn} ${
        isHeaderOpen ? '' : classes.hidden
      }`}
      onClick={() => {
        if (!isHeaderOpen) return;
        onClick((prevState) => !prevState);
      }}
    >
      <Bars />
    </button>
  );
};

export default HamburgerBtn;
