import { useContext } from 'react';
import { NavLink, useRouteMatch, useParams } from 'react-router-dom';
import AuthContext from '../../../contexts/auth-context';

import classes from './ZoneList.module.css';

const ZoneList = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const match = useRouteMatch();
  const url = match.path.replace(':stadiumId', params.stadiumId);

  const zoneList = authCtx.stadiumsList.find((stadium) => {
    return stadium.id === +params.stadiumId;
  }).zones;

  return (
    <ul className={classes.ZoneList}>
      {zoneList.map((zone) => (
        <li key={zone.id} className={classes['ZoneList__Item']}>
          <NavLink
            to={url.replace(':zoneId', zone.id)}
            className={classes['ZoneList__Link']}
            activeClassName={classes.active}
          >
            {zone.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default ZoneList;
