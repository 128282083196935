const Xmark = (props) => {
  const { color = '#222836' } = props;

  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0606 16L25.3933 7.66735L24.3326 6.60669L15.9999 14.9394L7.66837 6.60777L6.60771 7.66843L14.9393 16L6.60669 24.3326L7.66735 25.3933L15.9999 17.0607L24.3336 25.3944L25.3943 24.3337L17.0606 16Z'
        fill={color}
      />
    </svg>
  );
};

export default Xmark;
