import { useState } from 'react';

import AppointmentForm from '../../../Forms/AppointmentForm/AppointmentForm';
import Appointment from '../../Appointment/Appointment';
import classes from './TimeTableCell.module.css';

const TimeTableCell = (props) => {
  const {
    today,
    onChangeSchedulerState,
    schedulerState,
    cellDate,
    cellData = [],
  } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const formOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  const isToday =
    cellDate.toLocaleDateString() === today.toLocaleDateString()
      ? classes.today
      : '';
  const isThisMonth =
    cellDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
    }) ===
    schedulerState.date[6].toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
    })
      ? ''
      : classes.blur;
  const isSunday = !cellDate.getDay() ? classes.sunday : '';
  const classList = `${classes.TimeTableCell} ${isToday} ${isThisMonth} ${isSunday}`;

  return (
    <div className={classList}>
      <div
        className={classes['TimeTableCell__Inner']}
        onClick={formOpenHandler}
      >
        <div className={classes['TimeTableCell__Date']}>
          {cellDate.getDate()}
        </div>
        <ul className={classes['TimeTableCell__DataList']}>
          {cellData.map((data) => (
            <Appointment key={data.id} {...props} data={data} />
          ))}
        </ul>
        <div
          className={classes['TimeTableCell__ViewSwitcher']}
          onClick={() => {
            onChangeSchedulerState({ type: 'SWITCH_VIEW' });
            onChangeSchedulerState({ type: 'CUR_WEEK', date: cellDate });
          }}
        />
      </div>
      {isFormOpen && (
        <AppointmentForm date={cellDate} onClose={formOpenHandler} {...props} />
      )}
    </div>
  );
};

export default TimeTableCell;
