import AppointmentType from './AppointmentType';
import classes from './AppointmentsFilter.module.css';

const AppointmentsFilter = (props) => {
  const { appointmentTypes, filteredTypes, onChangeFilteredTypes } = props;

  return (
    <div className={classes.AppointmentsFilter}>
      {appointmentTypes.map((type, idx) => {
        if (type === '예약불가' || type === '예약가능') {
          return null;
        }
        return (
          <AppointmentType
            key={idx}
            attribute={{
              checked: filteredTypes.includes(type),
              onChange: () => onChangeFilteredTypes(type),
            }}
            appointmentType={type}
          />
        );
      })}
    </div>
  );
};

export default AppointmentsFilter;
