import { forwardRef } from 'react';

import classes from './InputText.module.css';

const InputText = forwardRef((props, ref) => {
  const { attribute, classList = [] } = props;
  const className = `${classes.InputText} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <div className={className}>
      <input
        {...attribute}
        ref={ref}
        value={attribute.value === null ? '' : attribute.value}
        autoComplete='off'
        className={classes['InputText__Input']}
      />
    </div>
  );
});

export default InputText;
