import { useContext } from 'react';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';

import AuthContext from '../../contexts/auth-context';
import Button from '../../components/UI/Input/Button/Button';
import classes from './NotFound.module.css';

const NotFound = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const match = useRouteMatch();
  const url = match.url.split('/')[1];

  if (!authCtx.isLoggedIn) {
    return <Redirect to='/login' />;
  }

  switch (url) {
    case 'schedule':
      return (
        <Redirect
          to={`/schedule/stadium/${authCtx.stadiumsList[0].id}/zone/${authCtx.stadiumsList[0].zones[0].id}`}
        />
      );
    case 'settlements':
      return (
        <Redirect
          to={`/settlements/stadium/${authCtx.stadiumsList[0].id}/zone/${authCtx.stadiumsList[0].zones[0].id}`}
        />
      );
    default:
      break;
  }

  return (
    <div className={classes.NotFound}>
      <p className={classes['NotFound__Paragraph']}>
        죄송합니다.
        <br />
        URL이 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
        없습니다.
      </p>
      <Button type='button' onClick={() => history.goBack()}>
        이전 페이지로 돌아가기
      </Button>
    </div>
  );
};

export default NotFound;
