import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import PlabLogo from '../../components/UI/Icons/PlabLogo/PlabLogo';
import Button from '../../components/UI/Input/Button/Button';
import InputText from '../../components/UI/Input/InputText';
import Loader from '../../components/UI/Loader/Loader';
import AuthContext from '../../contexts/auth-context';
import useHttp from '../../hooks/use-http';
import ChannelService from '../../components/ChannelTalk/ChannelService/ChannelService';
import classes from './LogIn.module.css';

const LogIn = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, sendRequest: fetchUserData } = useHttp();
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');

  const changeEmailHandler = (evt) => {
    setEnteredEmail(evt.target.value);
  };

  const changePasswordHandler = (evt) => {
    setEnteredPassword(evt.target.value);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const storeUserData = async (res) => {
      const data = await res.json();
      const storeStadiums = async (userData, res) => {
        const stadiumsData = await res.json();
        authCtx.login(
          userData.accessToken,
          userData.email.split('@')[0],
          userData.roleNames[0],
          userData.expiresDateTime,
          stadiumsData.stadiums
        );
      };

      if (res.ok) {
        fetchUserData(
          {
            urlPath: 'stadiums',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${data.accessToken}`,
            },
          },
          storeStadiums.bind(null, data)
        );
        return;
      }
      alert(data.message);
    };

    fetchUserData(
      {
        urlPath: 'auth/signIn',
        method: 'POST',
        body: {
          email: enteredEmail,
          password: enteredPassword,
        },
      },
      storeUserData
    );
  };

  useEffect(() => {
    if (authCtx.isLoggedIn && !!authCtx.stadiumsList) {
      history.replace(
        `/schedule/stadium/${authCtx.stadiumsList[0].id}/zone/${authCtx.stadiumsList[0].zones[0].id}`
      );
    }
  }, [authCtx.isLoggedIn, history, authCtx.stadiumsList]);

  useEffect(() => {
    const channelTalk = new ChannelService();
    if (!authCtx.isLoggedIn) {
      channelTalk.boot({
        pluginKey: '51c69b67-2a38-4099-a7d0-71151e22539e',
        zIndex: 200,
      });
    }

    return () => {
      channelTalk.shutdown();
    };
  }, [authCtx.isLoggedIn]);

  return (
    <section className={classes.LogIn}>
      <h1 className={classes['LogIn__Logo']}>
        <PlabLogo />
      </h1>
      <form onSubmit={submitHandler} className={classes.LogInForm}>
        <div className={classes['LogInForm__Row']}>
          <InputText
            attribute={{
              type: 'text',
              value: enteredEmail,
              placeholder: '아이디',
              onChange: changeEmailHandler,
            }}
          />
        </div>
        <div className={classes['LogInForm__Row']}>
          <InputText
            attribute={{
              type: 'password',
              value: enteredPassword,
              placeholder: '패스워드',
              onChange: changePasswordHandler,
            }}
          />
        </div>
        <div className={classes['LogInForm__Row']}>
          <Button attribute={{ type: 'submit' }}>로그인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </section>
  );
};

export default LogIn;
