import { useRef, useEffect } from 'react';

import Modal from '../../UI/Modal/Modal';
import FormControlWrap from '../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Button from '../../UI/Input/Button/Button';

import classes from './StadiumSearchPopUp.module.css';

const StadiumSearchPopUp = (props) => {
  const { stadiums, onChangeSelectedStadium, onClose } = props;
  const stadiumInputRef = useRef();

  const submitHandler = (evt) => {
    evt.preventDefault();

    const selectedStadium = stadiums.find(
      (stadium) => stadium.name === stadiumInputRef.current.value
    );

    if (selectedStadium) {
      onChangeSelectedStadium(selectedStadium.id);
      onClose();
    }
  };

  useEffect(() => {
    stadiumInputRef.current.focus();
  }, []);

  return (
    <Modal classList={['modalSmall']} hasHeader={false} onClose={onClose}>
      <div className={classes.ConfirmPopUp}>
        <br />
        <form onSubmit={submitHandler} className={classes.SearchForm}>
          <FormControlWrap>
            <input
              ref={stadiumInputRef}
              type='text'
              list='stadiums-list'
              placeholder='구장명 입력'
              className={classes['SearchForm__Input']}
            />
            <datalist id='stadiums-list'>
              {stadiums.map((stadium) => (
                <option key={stadium.id} value={stadium.name} />
              ))}
            </datalist>
          </FormControlWrap>
          <FormControlWrap>
            <Button attribute={{ type: 'submit' }}>확인</Button>
          </FormControlWrap>
        </form>
      </div>
    </Modal>
  );
};

export default StadiumSearchPopUp;
