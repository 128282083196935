import React, { useState } from 'react';
import useHttp from '../hooks/use-http';

const PenaltyCountContext = React.createContext({
  stadiumPenaltyCount: 0,
  accountPenaltyCount: 0,
  getStadiumPenaltyCount: () => {},
  getAccountPenaltyCount: () => {},
});

export const PenaltyCountProvider = ({ children }) => {
  const [stadiumPenaltyCount, setStadiumPenaltyCount] = useState(0);
  const [accountPenaltyCount, setAccountPenaltyCount] = useState(0);
  const { sendRequest: getPenaltyCount } = useHttp();

  const stadiumPenaltyCountHandler = (stadiumId) => {
    getPenaltyCount(
      {
        urlPath: `stadiums/${stadiumId}`,
      },
      async (res) => {
        const data = await res.json();

        if (res.ok) {
          setStadiumPenaltyCount(data.stadiumSocialRequestPenaltyCount);
        }
      }
    );
  };

  const accountPenaltyCountHandler = () => {
    getPenaltyCount(
      {
        urlPath: `accounts/penalty-count`,
      },
      async (res) => {
        const data = await res.json();

        if (res.ok) {
          setAccountPenaltyCount(data.penaltyCount);
        }
      }
    );
  };

  const contextValue = {
    stadiumPenaltyCount: stadiumPenaltyCount,
    getStadiumPenaltyCount: stadiumPenaltyCountHandler,
    accountPenaltyCount: accountPenaltyCount,
    getAccountPenaltyCount: accountPenaltyCountHandler,
  };

  return (
    <PenaltyCountContext.Provider value={contextValue}>
      {children}
    </PenaltyCountContext.Provider>
  );
};

export default PenaltyCountContext;
