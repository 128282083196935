import Modal from '../../../UI/Modal/Modal';
import Button from '../../../UI/Input/Button/Button';
import classes from './ConflictedSchedulePopUp.module.css';

const ConflictedSchedulePopUp = (props) => {
  const { onClose, time, conflictedSchedule } = props;
  const daysOfWeek = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];

  const conflictedDaysOfWeek = [];

  conflictedSchedule.forEach((schedule) => {
    const day = new Date(schedule.date).getDay();

    if (!conflictedDaysOfWeek.includes(day)) {
      conflictedDaysOfWeek.push(day);
    }
  });

  return (
    <Modal
      title='중복 일정 확인'
      paragraph={
        <span>
          대관 일정을 등록하려는 날짜에 이미 다른 상품이 등록되어 있습니다.
          <br />
          아래 일정을 취소 또는 수정 후 다시 시도해 주세요.
        </span>
      }
      onClose={onClose}
      classList={['modalSmall']}
    >
      <ul>
        {conflictedDaysOfWeek
          .sort((a, b) => a - b)
          .map((conflictedDay, idx) => (
            <li key={idx} className={classes['ConflictedSchedule__Item']}>
              <strong
                className={classes['ConflictedSchedule__Title']}
              >{`${daysOfWeek[conflictedDay]} ${time.startTime}~${time.endTime}`}</strong>
              <ul className={classes['ConflictedScheduleList']}>
                {conflictedSchedule.map((schedule, idx) => {
                  let productType = schedule.productType;
                  if (
                    schedule.productType === '소셜매치' &&
                    schedule.socialMatchStatus === 'NOT_CONFIRMED_CAN_RENT'
                  ) {
                    productType = '소셜대기/대관가능';
                  }

                  if (
                    schedule.productType === '소셜매치' &&
                    schedule.socialMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT'
                  ) {
                    productType = '소셜대기/대관불가';
                  }

                  const dayOfWeek = new Date(schedule.date).getDay();
                  if (dayOfWeek === conflictedDay) {
                    return (
                      <li
                        key={idx}
                        className={classes['ConflictedScheduleList__Item']}
                        data-product-type={productType}
                      >
                        {new Date(schedule.date).toLocaleDateString('ko-KR', {
                          month: 'long',
                          day: 'numeric',
                        })}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </li>
          ))}
      </ul>
      <div className={classes['ConflictedSchedulePopUp__Actions']}>
        <Button attribute={{ type: 'button' }} onClick={onClose}>
          확인
        </Button>
      </div>
    </Modal>
  );
};

export default ConflictedSchedulePopUp;
