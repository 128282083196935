import { Route, Switch, Redirect } from 'react-router-dom';

import LogIn from './pages/LogIn/LogIn';
import Schedule from './pages/Schedule/Schedule';
import SocialRequests from './pages/SocialRequests/SocialRequests';
import Settlements from './pages/Settlements/Settlements';
import NotFound from './pages/NotFound/NotFound';

const App = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/login' />
      <Route exact path='/login' component={LogIn} />
      <Route
        path='/schedule/stadium/:stadiumId/zone/:zoneId'
        component={Schedule}
      />
      <Route
        path='/social-requests/stadium/:stadiumId/zone/:zoneId'
        component={SocialRequests}
      />
      <Route
        path='/settlements/stadium/:stadiumId/zone/:zoneId'
        component={Settlements}
      />
      <Route path={['/not-found', '/*']} component={NotFound} />
    </Switch>
  );
};

export default App;
