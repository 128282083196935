import classes from './Checkbox.module.css';

const Checkbox = (props) => {
  const { attribute, classList = [] } = props;
  const className = `${classes.Checkbox} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <label htmlFor={attribute.id} className={className}>
      <input
        type='checkbox'
        {...attribute}
        className={classes['Checkbox__Input']}
      />
      <div className={classes['Checkbox__Text']}>{props.children}</div>
    </label>
  );
};

export default Checkbox;
