import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  userEmailId: '',
  userRole: '',
  stadiumsList: [],
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTiem = new Date();
  const adjExpirationTime = new Date(expirationTime);

  const remainingDuration = adjExpirationTime - currentTiem;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedUserEmailId = localStorage.getItem('userEmailId');
  const storedUserRole = localStorage.getItem('userRole');
  const storedStadiumsList = JSON.parse(localStorage.getItem('stadiumsList'));
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 60000) {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmailId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('stadiumsList');
    localStorage.removeItem('expirationTime');
    return null;
  }

  return {
    token: storedToken,
    userEmailId: storedUserEmailId,
    userRole: storedUserRole,
    stadiumsList: storedStadiumsList,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  const initialToken = tokenData ? tokenData.token : null;
  const [token, setToken] = useState(initialToken);
  const [userEmailId, setUserEmailId] = useState(
    tokenData ? tokenData.userEmailId : null
  );
  const [userRole, setUserRole] = useState(
    tokenData ? tokenData.userRole : null
  );
  const [stadiumsList, setStadiumsList] = useState(
    tokenData ? tokenData.stadiumsList : null
  );

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUserEmailId(null);
    setUserRole(null);
    setStadiumsList(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userEmailId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('stadiumsList');
    localStorage.removeItem('expirationTime');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (
    token,
    userEmailId,
    userRole,
    expirationTime,
    stadiumsList
  ) => {
    setToken(token);
    setUserEmailId(userEmailId);
    setUserRole(userRole);
    setStadiumsList(stadiumsList);
    localStorage.setItem('token', token);
    localStorage.setItem('userEmailId', userEmailId);
    localStorage.setItem('userRole', userRole);
    localStorage.setItem('stadiumsList', JSON.stringify(stadiumsList));
    localStorage.setItem('expirationTime', expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    userEmailId: userEmailId,
    userRole: userRole,
    stadiumsList: stadiumsList,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
