import classes from './CheckboxCircle.module.css';

const CheckboxCircle = (props) => {
  const { attribute, classList = [] } = props;
  const className = `${classes.CheckboxCircle} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <label htmlFor={attribute.id} className={className}>
      <input
        type='checkbox'
        {...attribute}
        className={classes['CheckboxCircle__Input']}
      />
      <div className={classes['CheckboxCircle__Text']}>{props.children}</div>
    </label>
  );
};

export default CheckboxCircle;
