import TimeTableCell from '../TimeTableCell/TimeTableCell';
import classes from './TimeTableRow.module.css';

const TimeTableRow = (props) => {
  const { timeSlot, appointments } = props;

  const timeTableCells = Array(7)
    .fill()
    .map((cell, idx) => (
      <TimeTableCell
        key={idx}
        cellDate={timeSlot[idx]}
        cellData={appointments[idx]}
        {...props}
      />
    ));

  return <div className={classes.TimeTableRow}>{timeTableCells}</div>;
};

export default TimeTableRow;
