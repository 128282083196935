import DateDisplay from './DateDisplay/DateDisplay';
import DateNavigation from './DateNavigation/DateNavigation';
import ViewSwitcher from './ViewSwitcher/ViewSwitcher';
import AppointmentsFilter from '../AppointmentsFilter/AppointmentsFilter';
import classes from './DateNavigator.module.css';

const DateNavigator = (props) => {
  return (
    <div className={classes.DateNavigator}>
      <DateDisplay {...props} />
      <DateNavigation {...props} />
      <ViewSwitcher {...props} />
      <AppointmentsFilter {...props} />
    </div>
  );
};

export default DateNavigator;
