import CalendarMonth from '../../../UI/Icons/Calendar/CalendarMonth';
import CalendarWeek from '../../../UI/Icons/Calendar/CalendarWeek';
import classes from './ViewSwitcher.module.css';

const ViewSwitcher = (props) => {
  const { schedulerState, onChangeSchedulerState } = props;

  const changeSchedulerState = () => {
    onChangeSchedulerState({ type: 'SWITCH_VIEW' });
  };

  return (
    <div className={classes.ViewSwitcher}>
      <button
        type='button'
        className={classes['ViewSwitcher__Btn']}
        onClick={changeSchedulerState}
      >
        {schedulerState.viewState === 'month' && <CalendarWeek />}
        {schedulerState.viewState === 'week' && <CalendarMonth />}
      </button>
    </div>
  );
};

export default ViewSwitcher;
