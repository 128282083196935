const Bars = (props) => {
  const { color = '#222836' } = props;

  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='4'
        y1='7.25'
        x2='28'
        y2='7.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <line
        x1='4'
        y1='15.25'
        x2='28'
        y2='15.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <line
        x1='4'
        y1='24.25'
        x2='28'
        y2='24.25'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default Bars;
