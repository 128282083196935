import { useRef } from 'react';

import Modal from '../../UI/Modal/Modal';
import Loader from '../../UI/Loader/Loader';
import InputText from '../../UI/Input/InputText';
import Button from '../../UI/Input/Button/Button';
import useHttp from '../../../hooks/use-http';
import classes from './NewPwdForm.module.css';

const NewPwdForm = (props) => {
  const { onClose } = props;
  const { isLoading, sendRequest: sendChangePwdRequest } = useHttp();
  const newPwdInputRef = useRef();
  const confirmNewPwdInputRef = useRef();

  const submitHandler = (evt) => {
    evt.preventDefault();
    const newPwdVal = newPwdInputRef.current.value;
    const confirmNewPwdVal = confirmNewPwdInputRef.current.value;

    if (newPwdVal !== confirmNewPwdVal) {
      alert('비밀번호가 일치하지 않습니다.');
      return confirmNewPwdInputRef.current.focus();
    }

    const getResObj = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return onClose();
      }

      alert(data.message);
    };

    sendChangePwdRequest(
      {
        urlPath: 'accounts/modify-password',
        method: 'PUT',
        body: {
          password: newPwdInputRef.current.value,
        },
      },
      getResObj
    );
  };

  return (
    <Modal title='비밀번호 변경' onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <InputText
            attribute={{
              type: 'password',
              id: 'new-password',
              placeholder: '변경할 비밀번호',
            }}
            ref={newPwdInputRef}
          />
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <InputText
            attribute={{
              type: 'password',
              id: 'confirm-new-password',
              placeholder: '변경할 비밀번호 확인',
            }}
            ref={confirmNewPwdInputRef}
          />
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button type='submit'>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default NewPwdForm;
