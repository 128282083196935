import { Fragment } from 'react';
import SchedulerBody from '../SchedulerLayout/SchedulerBody/SchedulerBody';
import DayScaleLayout from '../DayScaleLayout/DayScaleLayout';
import TimeTableLayout from './TimeTableLayout/TimeTableLayout';

const MonthView = (props) => {
  if (props.schedulerState.viewState === 'week') return null;
  return (
    <Fragment>
      <DayScaleLayout {...props} />
      <SchedulerBody>
        <TimeTableLayout {...props} />
      </SchedulerBody>
    </Fragment>
  );
};

export default MonthView;
