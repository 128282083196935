import ChevronLeft from '../../Icons/Chevron/ChevronLeft';
import classes from './Select.module.css';

const Select = (props) => {
  const {
    children,
    selectType,
    attribute,
    options,
    placeholder,
    classList = [],
  } = props;
  const className = `${classes.Select} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <div className={className}>
      <select
        {...attribute}
        className={`${classes['Select__Input']} ${
          attribute.value ? '' : classes['has-placeholder']
        }`}
      >
        {!attribute.value && (
          <option value='' disabled hidden>
            {placeholder}
          </option>
        )}
        {selectType !== 'object' &&
          options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        {selectType === 'object' &&
          options.map((option, idx) => (
            <option key={idx} value={option.optionVal || option.id}>
              {option.optionTxt || option.name}
            </option>
          ))}
      </select>
      <i className={classes['Select__Icon']}>
        <ChevronLeft />
      </i>
      {children}
    </div>
  );
};

export default Select;
