import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/UI/Input/Button/Button';
import Loader from '../../components/UI/Loader/Loader';
import AuthContext from '../../contexts/auth-context';
import PenaltyCountContext from '../../contexts/penalty-count';
import useHttp from '../../hooks/use-http';
import { adjDateFormat } from '../../utils/datetimeformat';
import SocialAddRequestList from './SocialAddRequestList/SocialAddRequestList';
import SocialMatches from './SocialMatchList/SocialMatchList';
import classes from './SocialRequest.module.css';
import SocialRequestList from './SocialRequestList/SocialRequestList';

const scheduleType = {
  INDIVIDUAL: 'INDIVIDUAL',
  FIXED: 'FIXED',
};

const SocialRequest = () => {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const PenaltyCountCxt = useContext(PenaltyCountContext);
  const [socialDataType, setSocialDataType] = useState(
    scheduleType['INDIVIDUAL']
  );
  const [socialMatchList, setSocialMatchList] = useState([]);
  const [socialAddRequestList, setSocialAddRequestList] = useState([]);
  const [socialRequestList, setSocialRequestList] = useState([]);
  const { isLoading, sendRequest } = useHttp();

  const zoneList = authCtx.stadiumsList?.find((stadium) => {
    return stadium.id === +params.stadiumId;
  }).zones;

  const socialDataTypeHandler = () => {
    setSocialDataType((prevState) =>
      prevState === scheduleType['INDIVIDUAL']
        ? scheduleType['FIXED']
        : scheduleType['INDIVIDUAL']
    );
  };

  useEffect(() => {
    const today = new Date();
    let startDate = adjDateFormat(today);
    let endDate = adjDateFormat(
      new Date(today.getFullYear(), today.getMonth() + 2, 1)
    );

    // 구장의 소셜매치 일정
    const storeSocialMatchList = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return setSocialMatchList(data.products);
      }
      setSocialMatchList([]);
      alert(data.message);
    };

    let socialMatchRequestUrlPath;
    if (socialDataType === scheduleType['INDIVIDUAL']) {
      socialMatchRequestUrlPath = `products?startDate=${startDate}&endDate=${endDate}&zoneId=${params.zoneId}&productType=소셜매치`;
    } else if (socialDataType === scheduleType['FIXED']) {
      socialMatchRequestUrlPath = `fixed-match/${params.zoneId}/`;
    }
    sendRequest(
      {
        urlPath: socialMatchRequestUrlPath,
      },
      storeSocialMatchList
    );

    // 소셜매치 추가 요청
    const storeSocialAddRequestList = async (res) => {
      const data = await res.json();

      if (res.ok) {
        return setSocialAddRequestList(data.socialRequests);
      }

      setSocialAddRequestList([]);
      alert(data.message);
    };

    let socialAddhRequestUrlPath;
    if (socialDataType === scheduleType['INDIVIDUAL']) {
      socialAddhRequestUrlPath = `social-requests/individual/?zoneId=${params.zoneId}&scheduleType=${socialDataType}&requestType=ADD`;
    } else if (socialDataType === scheduleType['FIXED']) {
      socialAddhRequestUrlPath = `social-requests/fixed/?zoneId=${params.zoneId}&requestType=ADD`;
    }

    sendRequest(
      {
        urlPath: socialAddhRequestUrlPath,
      },
      storeSocialAddRequestList,
      false
    );

    // 소셜매치 변경/취소 요청
    const storeSocialRequestList = async (res) => {
      const data = await res.json();
      if (res.ok) {
        return setSocialRequestList(data.socialRequests);
      }

      setSocialRequestList([]);
      alert(data.message);
    };

    let socialRequestUrlPath;
    if (socialDataType === scheduleType['INDIVIDUAL']) {
      socialRequestUrlPath = `social-requests/individual/?zoneId=${params.zoneId}&scheduleType=${socialDataType}`;
    } else if (socialDataType === scheduleType['FIXED']) {
      socialRequestUrlPath = `social-requests/fixed/?zoneId=${params.zoneId}`;
    }
    sendRequest(
      {
        urlPath: socialRequestUrlPath,
      },
      storeSocialRequestList,
      false
    );
    PenaltyCountCxt.getStadiumPenaltyCount(params.stadiumId);
  }, [params, socialDataType, sendRequest]);

  return (
    <>
      <div className={classes.ButtonsWrap}>
        <Button
          attribute={
            socialDataType === scheduleType['INDIVIDUAL']
              ? { type: 'button', tabIndex: -1 }
              : { type: 'button' }
          }
          classList={[
            'Button--fit-content',
            socialDataType === scheduleType['INDIVIDUAL']
              ? 'Button--Disabled'
              : '',
          ]}
          onClick={
            socialDataType === scheduleType['INDIVIDUAL']
              ? null
              : socialDataTypeHandler
          }
        >
          개별일정 보기
        </Button>
        <Button
          attribute={
            socialDataType === scheduleType['FIXED']
              ? { type: 'button', tabIndex: -1 }
              : { type: 'button' }
          }
          classList={[
            'Button--fit-content',
            socialDataType === scheduleType['FIXED'] ? 'Button--Disabled' : '',
          ]}
          onClick={
            socialDataType === scheduleType['FIXED']
              ? null
              : socialDataTypeHandler
          }
        >
          정기일정 보기
        </Button>
      </div>
      <SocialAddRequestList
        socialDataType={socialDataType}
        socialAddRequestList={socialAddRequestList}
        setSocialAddRequestList={setSocialAddRequestList}
        zoneList={zoneList}
      />
      <hr />
      <SocialRequestList
        socialDataType={socialDataType}
        socialRequestList={socialRequestList}
        setSocialRequestList={setSocialRequestList}
        setSocialMatchList={setSocialMatchList}
        zoneList={zoneList}
      />
      <hr />
      <SocialMatches
        socialDataType={socialDataType}
        socialMatchList={socialMatchList}
        setSocialMatchList={setSocialMatchList}
        setSocialRequestList={setSocialRequestList}
      />

      {isLoading && <Loader />}
    </>
  );
};

export default SocialRequest;
