import { useContext, useState } from 'react';
import AuthContext from '../../../contexts/auth-context';
import useHttp from '../../../hooks/use-http';
import ConfirmPopUp from '../../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../../UI/Input/Button/Button';
import Loader from '../../UI/Loader/Loader';
import ApprovalAndRejectionForm from '../SocialRequestList/ApprovalAndRejectionForm/ApprovalAndRejectionForm';
import SocialAddRequestForm from './SocialAddRequestForm/SocialAddRequestForm';
import classes from './SocialAddRequestList.module.css';

const socialRequestType = {
  ADD: '추가',
  CHANGE: '변경',
  CANCEL: '취소',
};

const REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

const YOIL = {
  MON: '월요일',
  TUE: '화요일',
  WED: '수요일',
  THU: '목요일',
  FRI: '금요일',
  SAT: '토요일',
  SUN: '일요일',
};

const SocialAddRequestList = (props) => {
  const { socialDataType, socialAddRequestList, setSocialAddRequestList } =
    props;
  const authCtx = useContext(AuthContext);
  const [requestComment, setRequestComment] = useState('');
  const [requestType, setRequestType] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState(false);
  const [isCancelPopUpOpen, setIsCancelPopUpOpen] = useState(false);
  const [isApprovalAndRejectionFormOpen, setIsApprovalAndRejectionFormOpen] =
    useState(false);
  const [isSocialAddRequestFormOpen, setIsSocialAddRequestForm] =
    useState(false);
  const { isLoading, sendRequest: sendCancelRequest } = useHttp();

  const confirmPopUpHandler = () => {
    setIsConfirmPopUpOpen((prevState) => !prevState);
  };
  const cancelPopUpHandler = () => {
    setIsCancelPopUpOpen((prevState) => !prevState);
  };
  const approvalAndRejectionFormOpenHandler = () => {
    setIsApprovalAndRejectionFormOpen((prevState) => !prevState);
  };

  const socialAddRequestFormOpenHandler = () => {
    setIsSocialAddRequestForm((prevState) => !prevState);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const updateSocialRequestStatue = async (res) => {
      const data = await res.json();

      if (res.ok) {
        setSocialAddRequestList((prevState) =>
          prevState.filter((request) => request.id !== data.id)
        );
        cancelPopUpHandler();
      }
    };

    sendCancelRequest(
      {
        urlPath: `social-requests/${socialDataType.toLowerCase()}/${selectedRequestId}`,
        method: 'DELETE',
      },
      updateSocialRequestStatue
    );
  };

  return (
    <>
      <div className={classes['SocialAddRequestList']}>
        <h2 className={classes['SocialAddRequestList__Title']}>
          {socialDataType === 'INDIVIDUAL'
            ? '개별일정 추가 요청'
            : '정기일정 추가 요청'}
        </h2>
        <div className={classes['SocialAddRequestList__ItemBox']}>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            요청
          </div>
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            {socialDataType === 'FIXED' ? '적용 월' : '일시'}
          </div>
          {socialDataType === 'FIXED' && (
            <div
              className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
            >
              타임
            </div>
          )}
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            요일
          </div>
          {socialDataType === 'INDIVIDUAL' && (
            <div
              className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
            >
              D-Day
            </div>
          )}
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Title']} `}
          >
            매니저 가능
          </div>
        </div>
        {!socialAddRequestList?.length && <>요청 내역이 없습니다.</>}
        {socialAddRequestList?.map((request) => {
          let date;
          let time;
          let matchTime;
          let dDay;
          if (socialDataType === 'INDIVIDUAL') {
            date = request?.startDateTime?.split('T')[0];
            time = request?.startDateTime?.split('T')[1];
            matchTime = `${date} ${time}`;
            const dDayNumber = Math.floor(
              (new Date(request?.startDateTime).setHours(0, 0, 0, 0) -
                new Date().setHours(0, 0, 0, 0)) /
                (1000 * 60 * 60 * 24)
            );
            dDay = `D${
              +dDayNumber < 0
                ? `+${Math.abs(dDayNumber)}`
                : `-${Math.abs(dDayNumber)}`
            }`;
          } else if (socialDataType === 'FIXED') {
            matchTime = request?.startDate;
          }
          return (
            <div
              key={request.id}
              className={classes['SocialAddRequestList__ItemBox']}
              onClick={() => {
                setRequestComment(request.comment);
                confirmPopUpHandler();
              }}
            >
              <div className={classes['SocialAddRequestList__Item']}>
                {socialRequestType[request.requestType]}
              </div>
              <div className={classes['SocialAddRequestList__Item']}>
                {matchTime}
              </div>
              {socialDataType === 'FIXED' && (
                <div className={classes['SocialAddRequestList__Item']}>
                  {request.startTime}
                </div>
              )}
              <div className={classes['SocialAddRequestList__Item']}>
                {socialDataType === 'INDIVIDUAL'
                  ? new Date(request.startDateTime).toLocaleDateString(
                      'ko-KR',
                      {
                        weekday: 'short',
                      }
                    )
                  : YOIL[request.yoil]}
              </div>
              {socialDataType === 'INDIVIDUAL' && (
                <div className={classes['SocialAddRequestList__Item']}>
                  {dDay}
                </div>
              )}
              <div className={classes['SocialAddRequestList__Item']}>
                {request.isManager ? 'O' : 'X'}
              </div>
              <div
                className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Actions']}`}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                {
                  // 구장주
                  request.requestStatus === REQUEST_STATUS['PENDING'] &&
                    authCtx.userRole !== '마스터' && (
                      <Button
                        attribute={{
                          type: 'submit',
                        }}
                        classList={['Button--fit-content']}
                        onClick={() => {
                          setSelectedRequestId(request.id);
                          cancelPopUpHandler();
                        }}
                      >
                        {'요청 취소'}
                      </Button>
                    )
                }
                {
                  // 구장주
                  request.requestStatus !== REQUEST_STATUS['PENDING'] &&
                    authCtx.userRole !== '마스터' && (
                      <Button
                        attribute={{
                          type: 'button',
                        }}
                        classList={[
                          'Button--fit-content',
                          request.requestStatus === REQUEST_STATUS['APPROVED']
                            ? 'Button--green'
                            : 'Button--red',
                        ]}
                        onClick={() => {}}
                      >
                        {request.requestStatus === REQUEST_STATUS['APPROVED']
                          ? '승인됨'
                          : '반려됨'}
                      </Button>
                    )
                }
                {(request.requestStatus === REQUEST_STATUS['APPROVED'] ||
                  request.requestStatus === REQUEST_STATUS['PENDING']) &&
                  authCtx.userRole === '마스터' && (
                    <Button
                      attribute={{
                        type: 'button',
                        tabIndex:
                          request.requestStatus !== REQUEST_STATUS['PENDING']
                            ? -1
                            : null,
                      }}
                      classList={[
                        'Button--fit-content',
                        request.requestStatus !== REQUEST_STATUS['PENDING']
                          ? 'Button--Disabled'
                          : '',
                        request.requestStatus === REQUEST_STATUS['APPROVED']
                          ? 'Button--green'
                          : '',
                      ]}
                      onClick={() => {
                        setSelectedRequestId(request.id);
                        setRequestType('승인');
                        if (request.requestStatus === REQUEST_STATUS['PENDING'])
                          approvalAndRejectionFormOpenHandler();
                      }}
                    >
                      {request.requestStatus === REQUEST_STATUS['APPROVED']
                        ? '승인됨'
                        : '승인'}
                    </Button>
                  )}
                {(request.requestStatus === REQUEST_STATUS['REJECTED'] ||
                  request.requestStatus === REQUEST_STATUS['PENDING']) &&
                  authCtx.userRole === '마스터' && (
                    <Button
                      attribute={{
                        type: 'button',
                        tabIndex:
                          request.requestStatus !== REQUEST_STATUS['PENDING']
                            ? -1
                            : null,
                      }}
                      classList={[
                        'Button--fit-content',
                        'Button--Delete',
                        request.requestStatus !== REQUEST_STATUS['PENDING']
                          ? 'Button--Disabled'
                          : '',
                        request.requestStatus === REQUEST_STATUS['REJECTED']
                          ? 'Button--red'
                          : '',
                      ]}
                      onClick={() => {
                        setSelectedRequestId(request.id);
                        setRequestType('반려');
                        if (request.requestStatus === REQUEST_STATUS['PENDING'])
                          approvalAndRejectionFormOpenHandler();
                      }}
                    >
                      {request.requestStatus === REQUEST_STATUS['REJECTED']
                        ? '반려됨'
                        : '반려'}
                    </Button>
                  )}
              </div>
            </div>
          );
        })}
        {authCtx.userRole !== '마스터' && (
          <div
            className={`${classes['SocialAddRequestList__Item']} ${classes['SocialAddRequestList__Item--Add-Btn']} `}
          >
            <Button
              attribute={{ type: 'button' }}
              classList={['Button--fit-content']}
              onClick={socialAddRequestFormOpenHandler}
            >
              추가 요청하기
            </Button>
          </div>
        )}
      </div>
      {isApprovalAndRejectionFormOpen && (
        <ApprovalAndRejectionForm
          title={requestType}
          socialDataType={socialDataType}
          socialRequestList={socialAddRequestList}
          onSetSocialRequestList={setSocialAddRequestList}
          selectedRequestId={selectedRequestId}
          onClose={approvalAndRejectionFormOpenHandler}
        />
      )}
      {isSocialAddRequestFormOpen && (
        <SocialAddRequestForm
          title='추가 요청'
          socialDataType={socialDataType}
          onSetSocialAddRequestList={setSocialAddRequestList}
          selectedRequestId={selectedRequestId}
          onClose={socialAddRequestFormOpenHandler}
        />
      )}
      {isConfirmPopUpOpen && (
        <ConfirmPopUp
          paragraph={
            requestComment ? requestComment : '작성된 메모가 없습니다.'
          }
          onClose={confirmPopUpHandler}
        />
      )}
      {isCancelPopUpOpen && (
        <ConfirmPopUp
          paragraph={'요청을 취소하시겠습니까?'}
          onClose={cancelPopUpHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default SocialAddRequestList;
