import classes from './AppointmentType.module.css';

const AppointmentType = (props) => {
  const { attribute, appointmentType, classList = [] } = props;
  const className = `${classes.AppointmentType} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <label data-product-type={appointmentType} className={className}>
      <input
        type='checkbox'
        {...attribute}
        className={classes['AppointmentType__Input']}
      />
      <div className={classes['AppointmentType__Text']}>{appointmentType}</div>
    </label>
  );
};

export default AppointmentType;
