import { useContext, useState, useCallback } from 'react';
import AuthContext from '../contexts/auth-context';

const useHttp = () => {
  let apiUrl;
  if (process.env.NODE_ENV === 'development') {
    apiUrl = process.env.REACT_APP_LOCAL_API_URL;
  }

  if (process.env.NODE_ENV === 'production') {
    apiUrl = process.env.REACT_APP_BASE_API_URL;
  }

  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (requestConfig, returnResObj, loader = true) => {
      if (loader) setIsLoading(true);

      const response = await fetch(`${apiUrl}/${requestConfig.urlPath}`, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers
          ? requestConfig.headers
          : {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      returnResObj(response);
      if (loader) setIsLoading(false);
    },
    [apiUrl, authCtx.token]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
