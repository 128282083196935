import TimeTableCell from '../TimeTableCell/TimeTableCell';
import classes from './TimeTableRow.module.css';

const TimeTableRow = (props) => {
  const { adjDateFormat, daysOfWeek, monthlyAppointmentList = [] } = props;
  const filteredAppointments = Array(7)
    .fill()
    .map((rowData) => []);

  for (let idx in daysOfWeek) {
    monthlyAppointmentList.forEach((appointment) => {
      if (
        appointment.productType === '예약가능' ||
        appointment.productType === '예약불가'
      )
        return;
      if (appointment.date === adjDateFormat(daysOfWeek[idx])) {
        filteredAppointments[idx].push(appointment);
      }
    });
  }

  const timeTableCells = Array(7)
    .fill()
    .map((cell, idx) => (
      <TimeTableCell
        key={idx}
        cellDate={daysOfWeek[idx]}
        cellData={filteredAppointments[idx]}
        {...props}
      />
    ));

  return <div className={classes.TimeTableRow}>{timeTableCells}</div>;
};

export default TimeTableRow;
