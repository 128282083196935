import classes from './Button.module.css';

const Button = (props) => {
  const { attribute, classList = [], onClick } = props;
  const className = `${classes.Button} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <button {...attribute} className={className} onClick={onClick}>
      {props.children}
    </button>
  );
};

export default Button;
