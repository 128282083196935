import ChevronLeft from '../../../UI/Icons/Chevron/ChevronLeft';
import ChevronRight from '../../../UI/Icons/Chevron/ChevronRight';
import classes from './DateNavigation.module.css';

const DateNavigation = (props) => {
  const { today, schedulerState, onChangeSchedulerState } = props;

  const prevDateHandler = () => {
    if (schedulerState.viewState === 'month') {
      onChangeSchedulerState({ type: 'PREV_MONTH' });
    }

    if (schedulerState.viewState === 'week') {
      onChangeSchedulerState({ type: 'PREV_WEEK' });
    }
  };

  const nextDateHandler = () => {
    if (schedulerState.viewState === 'month') {
      onChangeSchedulerState({ type: 'NEXT_MONTH' });
    }

    if (schedulerState.viewState === 'week') {
      onChangeSchedulerState({ type: 'NEXT_WEEK' });
    }
  };

  const curDateHandler = () => {
    if (schedulerState.viewState === 'month') {
      onChangeSchedulerState({ type: 'CUR_MONTH', date: today });
    }

    if (schedulerState.viewState === 'week') {
      onChangeSchedulerState({ type: 'CUR_WEEK', date: today });
    }
  };

  return (
    <div className={classes.DateNavigation}>
      <button
        type='button'
        className={classes['DateNavigation__PrevBtn']}
        onClick={prevDateHandler}
      >
        <ChevronLeft />
      </button>
      <button
        type='button'
        className={classes['DateNavigation__NextBtn']}
        onClick={nextDateHandler}
      >
        <ChevronRight />
      </button>
      <button
        type='button'
        className={classes['DateNavigation__TodayBtn']}
        onClick={curDateHandler}
      >
        오늘
      </button>
    </div>
  );
};

export default DateNavigation;
