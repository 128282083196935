import classes from './StadiumSelect.module.css';

const StadiumSelect = (props) => {
  const { selectedStadiumId, stadiums, onChangeSelectedStadium } = props;

  return (
    <>
      <div className={classes.StadiumSelect}>
        <select
          value={selectedStadiumId}
          onChange={(evt) => onChangeSelectedStadium(evt.target.value)}
          className={classes['StadiumSelect__Select']}
        >
          {stadiums.map((stadium) => (
            <option key={stadium.id} value={stadium.id}>
              {stadium.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default StadiumSelect;
