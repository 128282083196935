import { useContext } from 'react';
import AuthContext from '../../../contexts/auth-context';
import UserSetting from './UserSetting/UserSetting';
import classes from './UtilityMenu.module.css';

const UtilityMenu = (props) => {
  const authCtx = useContext(AuthContext);

  return (
    <div className={classes.UtilityMenu}>
      <strong className={classes['UtilityMenu__Title']}>계정 설정</strong>
      <UserSetting />
      <div className={classes['UtilityMenu__Actions']}>
        <button type='button' onClick={() => authCtx.logout()}>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default UtilityMenu;
