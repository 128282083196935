import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PenaltyCountContext from '../../../../contexts/penalty-count';
import useHttp from '../../../../hooks/use-http';
import FormControlWrap from '../../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Button from '../../../UI/Input/Button/Button';
import Checkbox from '../../../UI/Input/Checkbox';
import InputPrice from '../../../UI/Input/InputPrice';
import InputRadio from '../../../UI/Input/InputRadio';
import InputText from '../../../UI/Input/InputText';
import Select from '../../../UI/Input/Select/Select';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import ToggleBtn from '../../../UI/Input/ToggleBtn/ToggleBtn';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import classes from './ApprovalAndRejectionForm.module.css';

const REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
const SEX_OPTIONS = [
  { optionVal: 'MALE', name: '남성매치' },
  { optionVal: 'FEMALE', name: '여성매치' },
  { optionVal: 'BOTH', name: '남녀 모두 매치' },
];
const LEVEL_OPTIONS = [
  { optionVal: 'ALL', name: '누구나' },
  { optionVal: 'BEGINNER', name: '초급' },
  { optionVal: 'LTE_AMATEUR_1', name: '아마추어1 이하' },
  { optionVal: 'LTE_AMATEUR_2', name: '아마추어2 이하' },
  { optionVal: 'GTE_AMATEUR_2', name: '아마추어2 이상' },
  { optionVal: 'GTE_SEMI_1', name: '세미프로₩1 이상' },
];
const MATCH_TYPE_OPTIONS = [
  { optionVal: 'match', name: '매치' },
  { optionVal: 'lesson', name: '레슨' },
  { optionVal: 'hosting', name: '호스팅' },
  { optionVal: '3teams', name: '삼파전' },
  { optionVal: 'fit', name: '플랩핏' },
  { optionVal: 'starter', name: '스타터매치' },
  { optionVal: 'tshirt', name: '티셔츠매치' },
  { optionVal: 'league', name: '리그' },
];
const PLAYTIME = [
  { optionVal: 90, name: '90분' },
  { optionVal: 105, name: '105분' },
  { optionVal: 120, name: '120분' },
];
const MATCH_STATUS = [
  { optionVal: 'RELEASE', name: '공개' },
  { optionVal: 'READY', name: '대기' },
  { optionVal: 'CANCEL', name: '취소' },
  { optionVal: 'HIDE', name: '숨기기' },
];

const ApprovalAndRejectionForm = (props) => {
  const {
    title,
    socialDataType,
    socialRequestList,
    onSetSocialRequestList,
    selectedRequestId,
    onClose,
  } = props;
  const params = useParams();
  const PenaltyCountCtx = useContext(PenaltyCountContext);
  const [enteredIsPenalty, setEnteredIsPenalty] = useState(false);
  const [isReadyMatch, setIsReadyMatch] = useState(false);
  const [selectedMatchStatus, setSelectedMatchStatus] = useState('CONFIRMED');
  const [enteredPrice, setEnteredPrice] = useState(100000);
  const [enteredManagerId, setEnteredManagerId] = useState('');
  const [enteredStadiumFee, setEnteredStadiumFee] = useState('');
  const [enteredCancelPer, setEnteredCancelPer] = useState('');
  const [enteredSex, setEnteredSex] = useState(SEX_OPTIONS[2].optionVal);
  const [enteredLevel, setEnteredLevel] = useState(LEVEL_OPTIONS[0].optionVal);
  const [enteredMatchType, setEnteredMatchType] = useState(
    MATCH_TYPE_OPTIONS[3].optionVal
  );
  const [enteredMatchStatus, setEnteredMatchStatus] = useState(
    MATCH_STATUS[1].optionVal
  );
  const [enteredPlaytime, setEnteredPlaytime] = useState(PLAYTIME[2].optionVal);
  const [enteredExcludeWeeks, setEnteredExcludeWeeks] = useState('');
  const [enteredPlayerCnt, setEnteredPlayerCnt] = useState('');
  const [enteredMinPlayerCnt, setEnteredMinPlayerCnt] = useState('');
  const [enteredMaxPlayerCnt, setEnteredMaxPlayerCnt] = useState('');
  const [enteredMemo, setEnteredMemo] = useState('');
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const requestType = socialRequestList.find(
    (request) => request.id === selectedRequestId
  ).requestType;

  const changeIsPenaltyHandler = (evt) => {
    setEnteredIsPenalty(evt.target.checked);
  };
  const changeIsReadyMatchHandler = () => {
    setIsReadyMatch((prevState) => {
      if (!prevState) {
        setSelectedMatchStatus('NOT_CONFIRMED_CAN_NOT_RENT');
      } else {
        setSelectedMatchStatus('CONFIRMED');
      }
      return !prevState;
    });
  };
  const changeSelectedMatchStatusHandler = (evt) => {
    setSelectedMatchStatus(evt.target.id);
  };
  const changePriceHandler = (evt) => {
    setEnteredPrice(Number(evt.target.value.replaceAll(',', '')));
  };
  const changeManagerIdHandler = (evt) => {
    setEnteredManagerId(evt.target.value);
  };
  const changeStadiumFeeHandler = (evt) => {
    setEnteredStadiumFee(evt.target.value);
  };
  const changeCancelPerHandler = (evt) => {
    setEnteredCancelPer(evt.target.value);
  };
  const changeSexHandler = (evt) => {
    setEnteredSex(evt.target.value);
  };
  const changeLevelHandler = (evt) => {
    setEnteredLevel(evt.target.value);
  };
  const changeMatchTypeHandler = (evt) => {
    setEnteredMatchType(evt.target.value);
  };
  const changeMatchStatusHandler = (evt) => {
    setEnteredMatchStatus(evt.target.value);
  };
  const changePlaytimeHandler = (evt) => {
    setEnteredPlaytime(evt.target.value);
  };
  const changeExcludeWeeksHandler = (evt) => {
    setEnteredExcludeWeeks(evt.target.value);
  };
  const changePlayerCntHandler = (evt) => {
    setEnteredPlayerCnt(evt.target.value);
  };
  const changeMinPlayerCntHandler = (evt) => {
    setEnteredMinPlayerCnt(evt.target.value);
  };
  const changeMaxPlayerCntHandler = (evt) => {
    setEnteredMaxPlayerCnt(evt.target.value);
  };
  const changeMemoHandler = (evt) => {
    setEnteredMemo(evt.target.value);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialRequestList((prevState) => {
          return prevState.map((request) => {
            if (request.id === data.socialRequestId) {
              if (title === '반려') {
                request.requestStatus = REQUEST_STATUS['REJECTED'];
              } else if (title === '승인') {
                request.requestStatus = REQUEST_STATUS['APPROVED'];
                request.hasPenalty = enteredIsPenalty;
                if (enteredIsPenalty) {
                  PenaltyCountCtx.getStadiumPenaltyCount(params.stadiumId);
                  PenaltyCountCtx.getAccountPenaltyCount();
                }
              }
              return request;
            }
            return request;
          });
        });
        return;
      }

      alert(data.message);
    };

    if (title === '반려') {
      sendSocialRequest(
        {
          urlPath: 'rejected-social-request/',
          method: 'POST',
          body: {
            social_request_id: selectedRequestId,
            comment: enteredMemo,
          },
        },
        createSocialRequest
      );
    } else if (title === '승인') {
      if (socialDataType === 'INDIVIDUAL') {
        let matchStatus;
        let price;
        if (isReadyMatch && selectedMatchStatus === 'NOT_CONFIRMED_CAN_RENT') {
          matchStatus = selectedMatchStatus;
          price = enteredPrice;
        } else if (
          isReadyMatch &&
          selectedMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT'
        ) {
          matchStatus = selectedMatchStatus;
          price = null;
        } else {
          matchStatus = 'CONFIRMED';
          price = null;
        }

        sendSocialRequest(
          {
            urlPath: `social-requests/individual/${selectedRequestId}/approval/`,
            method: 'PUT',
            body: {
              matchStatus: requestType === 'ADD' ? matchStatus : null,
              price: requestType === 'ADD' ? price : null,
              comment: enteredMemo,
              isPenalty: enteredIsPenalty,
            },
          },
          createSocialRequest
        );
      } else if (socialDataType === 'FIXED') {
        sendSocialRequest(
          {
            urlPath: `social-requests/fixed/${selectedRequestId}/approval/`,
            method: 'PUT',
            body: {
              managerId: enteredManagerId,
              stadiumFee: enteredStadiumFee,
              cancelPer: enteredCancelPer,
              sex: enteredSex,
              level: enteredLevel,
              matchType: enteredMatchType,
              matchStatus: enteredMatchStatus,
              playtime: enteredPlaytime,
              excludeWeeks: enteredExcludeWeeks,
              playerCnt: enteredPlayerCnt,
              minPlayerCnt: enteredMinPlayerCnt,
              maxPlayerCnt: enteredMaxPlayerCnt,
              comment: enteredMemo,
            },
          },
          createSocialRequest
        );
      }
    }
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        {title === '승인' &&
          socialDataType === 'INDIVIDUAL' &&
          requestType === 'CANCEL' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label htmlFor='penalty-option' className={classes.InputLabel}>
                  페널티
                </label>
                <Checkbox
                  attribute={{
                    name: 'penalty-option',
                    onChange: changeIsPenaltyHandler,
                    defaultChecked: enteredIsPenalty,
                  }}
                />
              </FormControlWrap>
            </div>
          )}
        {title === '승인' &&
          socialDataType === 'INDIVIDUAL' &&
          requestType === 'ADD' && (
            <>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap classList={['space-between']}>
                  <label
                    htmlFor='match-status-toggle'
                    className={classes.InputLabel}
                  >
                    대기매치
                  </label>
                  <ToggleBtn
                    attribute={{
                      id: 'match-status-toggle',
                      onChange: changeIsReadyMatchHandler,
                      checked: isReadyMatch,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div
                className={`${classes['AppointmentForm__RowsWrap']} ${
                  classes.toggle
                } ${isReadyMatch ? '' : classes.hidden}`}
              >
                <FormControlWrap>
                  <label
                    htmlFor='match-status-option'
                    className={`${classes.InputLabel} ${classes.hidden}`}
                  >
                    대기매치 타입
                  </label>
                  <InputRadio
                    attribute={{
                      id: 'NOT_CONFIRMED_CAN_NOT_RENT',
                      name: 'repeat-option',
                      onClick: changeSelectedMatchStatusHandler,
                      value: selectedMatchStatus,
                      defaultChecked: true,
                    }}
                  >
                    대관불가
                  </InputRadio>
                  <InputRadio
                    attribute={{
                      id: 'NOT_CONFIRMED_CAN_RENT',
                      name: 'repeat-option',
                      value: selectedMatchStatus,
                      onClick: changeSelectedMatchStatusHandler,
                    }}
                  >
                    대관가능
                  </InputRadio>
                </FormControlWrap>
              </div>
              {selectedMatchStatus === 'NOT_CONFIRMED_CAN_RENT' && (
                <div className={classes['AppointmentForm__Row']}>
                  <FormControlWrap>
                    <label
                      htmlFor='product-price'
                      className={classes.InputLabel}
                    >
                      가격
                    </label>
                    <InputPrice
                      attribute={{
                        id: 'product-price',
                        value: enteredPrice,
                        onChange: changePriceHandler,
                      }}
                    />
                  </FormControlWrap>
                </div>
              )}
            </>
          )}
        {title === '승인' &&
          socialDataType === 'FIXED' &&
          requestType === 'ADD' && (
            <>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor={'manager-id'} className={classes.InputLabel}>
                    매니저
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'manager-id',
                      placeholder: '매니저 ID',
                      value: enteredManagerId,
                      onChange: changeManagerIdHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor={'stadium-fee'} className={classes.InputLabel}>
                    대관료
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'stadium-fee',
                      placeholder: '대관료',
                      value: enteredStadiumFee,
                      onChange: changeStadiumFeeHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor={'cancel-per'} className={classes.InputLabel}>
                    정산율
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'cancel-per',
                      placeholder: '취소 정산율',
                      value: enteredCancelPer,
                      onChange: changeCancelPerHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='sex' className={classes.InputLabel}>
                    성별
                  </label>
                  <Select
                    selectType='object'
                    attribute={{
                      id: 'sex',
                      value: enteredSex,
                      onChange: changeSexHandler,
                    }}
                    options={SEX_OPTIONS}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='level' className={classes.InputLabel}>
                    레벨
                  </label>
                  <Select
                    selectType='object'
                    attribute={{
                      id: 'level',
                      value: enteredLevel,
                      onChange: changeLevelHandler,
                    }}
                    options={LEVEL_OPTIONS}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='match-type' className={classes.InputLabel}>
                    타입
                  </label>
                  <Select
                    selectType='object'
                    attribute={{
                      id: 'match-type',
                      value: enteredMatchType,
                      onChange: changeMatchTypeHandler,
                    }}
                    options={MATCH_TYPE_OPTIONS}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='match-status' className={classes.InputLabel}>
                    상태
                  </label>
                  <Select
                    selectType='object'
                    attribute={{
                      id: 'match-status',
                      value: enteredMatchStatus,
                      onChange: changeMatchStatusHandler,
                    }}
                    options={MATCH_STATUS}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor='playtime' className={classes.InputLabel}>
                    플레이
                  </label>
                  <Select
                    selectType='object'
                    attribute={{
                      id: 'playtime',
                      value: enteredPlaytime,
                      onChange: changePlaytimeHandler,
                    }}
                    options={PLAYTIME}
                    classList={['has-placeholder']}
                    placeholder='플레이 타임'
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={'excludeWeeks'}
                    className={classes.InputLabel}
                  >
                    매없주
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'excludeWeeks',
                      placeholder: '매없주',
                      value: enteredExcludeWeeks,
                      onChange: changeExcludeWeeksHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label htmlFor={'player-cnt'} className={classes.InputLabel}>
                    플레이어
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'player-cnt',
                      placeholder: '플레이어',
                      value: enteredPlayerCnt,
                      onChange: changePlayerCntHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={'min-player-cnt'}
                    className={classes.InputLabel}
                  >
                    최소인원
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'min-player-cnt',
                      placeholder: '최소인원',
                      value: enteredMinPlayerCnt,
                      onChange: changeMinPlayerCntHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
              <div className={classes['AppointmentForm__Row']}>
                <FormControlWrap>
                  <label
                    htmlFor={'max-player-cnt'}
                    className={classes.InputLabel}
                  >
                    최대인원
                  </label>
                  <InputText
                    attribute={{
                      type: 'text',
                      id: 'max-player-cnt',
                      placeholder: '최대인원',
                      value: enteredMaxPlayerCnt,
                      onChange: changeMaxPlayerCntHandler,
                    }}
                  />
                </FormControlWrap>
              </div>
            </>
          )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='note'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              메모
            </label>
            <Textarea
              attribute={{
                id: 'note',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default ApprovalAndRejectionForm;
