import classes from './InputDate.module.css';

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const InputDate = (props) => {
  const { attribute, visibleText = '', classList = [] } = props;
  const className = `${classes.InputDate} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  const dateVal = attribute.value && adjDateFormat(attribute.value);

  const dateTxt =
    attribute.value &&
    attribute.value.toLocaleDateString(
      'ko-KR',
      visibleText === 'month'
        ? {
            year: 'numeric',
            month: 'long',
          }
        : {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          }
    );

  return (
    <div className={className}>
      <input
        type='date'
        {...attribute}
        className={classes['InputDate__Input']}
        value={dateVal}
      />
      <div className={classes['InputDate__Text']}>{dateTxt}</div>
    </div>
  );
};

export default InputDate;
