import { useState } from 'react';

import Appointment from '../../Appointment/Appointment';
import AppointmentForm from '../../../Forms/AppointmentForm/AppointmentForm';
import classes from './TimeTableCell.module.css';

const TimeTableCell = (props) => {
  const { today, cellDate, cellData } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const formOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  const isToday =
    cellDate.toLocaleDateString() === today.toLocaleDateString()
      ? classes.today
      : '';
  const classList = `${classes.TimeTableCell} ${isToday}`;

  if (cellData) {
    return (
      <div className={classList}>
        <div
          className={classes['TimeTableCell__Inner']}
          onClick={formOpenHandler}
        >
          {cellData.map((item, idx) => (
            <Appointment key={idx} {...props} data={item} />
          ))}
        </div>
        {isFormOpen && (
          <AppointmentForm
            {...props}
            date={cellDate}
            onClose={formOpenHandler}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classList}>
      <div
        className={classes['TimeTableCell__Inner']}
        onClick={formOpenHandler}
      ></div>
      {isFormOpen && (
        <AppointmentForm {...props} date={cellDate} onClose={formOpenHandler} />
      )}
    </div>
  );
};

export default TimeTableCell;
