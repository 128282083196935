const ChevronLeft = (props) => {
  const { color = '#222836' } = props;

  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M23 4L9 15.1429L23 28' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};

export default ChevronLeft;
