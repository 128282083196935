import { useContext, useState } from 'react';
import AuthContext from '../../../contexts/auth-context';
import useHttp from '../../../hooks/use-http';
import ConfirmPopUp from '../../PopUps/ConfirmPopUp/ConfirmPopUp';
import Button from '../../UI/Input/Button/Button';
import Loader from '../../UI/Loader/Loader';
import ApprovalAndRejectionForm from './ApprovalAndRejectionForm/ApprovalAndRejectionForm';
import classes from './SocialRequestList.module.css';

const socialMatchStatus = {
  RELEASE: '공개',
  READY: '대기',
  HIDE: '취소',
};
const socialRequestType = {
  ADD: '추가',
  CHANGE: '변경',
  CANCEL: '취소',
};
const REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
const socialRequestReason = {
  BAD_WEATHER: '기상악화',
  EVENT: '대회/행사',
  FACILITY_ISSUE: '시설문제',
  OWNER_DIRECT: '구장직접',
  LONG_TERM_RENTAL: '장기대관',
  ACADEMY: '아카데미',
  OTHER: '기타',
};
const YOIL = {
  MON: '월요일',
  TUE: '화요일',
  WED: '수요일',
  THU: '목요일',
  FRI: '금요일',
  SAT: '토요일',
  SUN: '일요일',
};
const SEX = {
  '-1': '여성매치',
  0: '남녀 모두 매치',
  1: '남성매치',
};
const LEVEL = {
  0: '누구나',
  1: '초급',
  2: '중급',
  3: '아마추어1 이하',
  4: '아마추어2 이하',
  5: '아마추어2 이상',
  6: '세미프로1 이상',
};

const SocialRequestList = (props) => {
  const { socialDataType, socialRequestList, setSocialRequestList, zoneList } =
    props;
  const authCtx = useContext(AuthContext);
  const [isApprovalAndRejectionFormOpen, setIsApprovalAndRejectionFormOpen] =
    useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [requestComment, setRequestComment] = useState('');
  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState(false);
  const [isCancelPopUpOpen, setIsCancelPopUpOpen] = useState(false);
  const { isLoading, sendRequest: sendCancelRequest } = useHttp();

  const confirmPopUpHandler = () => {
    setIsConfirmPopUpOpen((prevState) => !prevState);
  };

  const cancelPopUpHandler = () => {
    setIsCancelPopUpOpen((prevState) => !prevState);
  };

  const ApprovalAndRejectionFormOpenHandler = () => {
    setIsApprovalAndRejectionFormOpen((prevState) => !prevState);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    const updateSocialRequestStatue = async (res) => {
      const data = await res.json();

      if (res.ok) {
        setSocialRequestList((prevState) =>
          prevState.filter((request) => request.id !== data.id)
        );
        cancelPopUpHandler();
      }
    };

    sendCancelRequest(
      {
        urlPath: `social-requests/${socialDataType.toLowerCase()}/${selectedRequestId}`,
        method: 'DELETE',
      },
      updateSocialRequestStatue
    );
  };

  return (
    <>
      <div className={classes['SocialRequestList']}>
        <h2 className={classes['SocialRequestList__Title']}>
          {socialDataType === 'INDIVIDUAL'
            ? '개별일정 변경/취소 요청'
            : '정기일정 변경/취소 요청'}
        </h2>
        <div className={classes['SocialRequestList__ItemBox']}>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            요청
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            사유
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            상태
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            {socialDataType === 'FIXED' ? '타임' : '일시'}
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            요일
          </div>
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            매니저
          </div>
          {socialDataType === 'FIXED' && (
            <>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                성별
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                레벨
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                플레이 타임
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                플레이어
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                제외
              </div>
            </>
          )}
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
          >
            구역
          </div>
          {socialDataType === 'INDIVIDUAL' && (
            <>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                D-Day
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                신청 현황
              </div>
              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Title']}`}
              >
                페널티
              </div>
            </>
          )}
          <div
            className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Actions']} `}
          >
            &nbsp;
          </div>
        </div>
        {!socialRequestList?.length && <>요청 내역이 없습니다.</>}
        {socialRequestList?.map((request) => {
          const zone = zoneList.find((zone) => zone.id === request?.zoneId);
          let matchTime;
          let dDay;
          if (socialDataType === 'INDIVIDUAL') {
            const date = request?.startDateTime?.split('T')[0];
            const time = request?.startDateTime?.split('T')[1];
            matchTime = `${date} ${time}`;
            const dDayNumber = Math.floor(
              (new Date(request?.startDateTime).setHours(0, 0, 0, 0) -
                new Date().setHours(0, 0, 0, 0)) /
                (1000 * 60 * 60 * 24)
            );
            dDay = `D${
              +dDayNumber < 0
                ? `+${Math.abs(dDayNumber)}`
                : `-${Math.abs(dDayNumber)}`
            }`;
          } else if (socialDataType === 'FIXED') {
            matchTime = request.startTime;
          }

          return (
            <div
              key={request.id}
              className={classes['SocialRequestList__ItemBox']}
              onClick={() => {
                setRequestComment(request.comment);
                confirmPopUpHandler();
              }}
            >
              <div className={classes['SocialRequestList__Item']}>
                {socialRequestType[request.requestType]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {socialRequestReason[request.reason]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {socialMatchStatus[request.socialMatchStatus]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {matchTime}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {socialDataType === 'INDIVIDUAL'
                  ? new Date(matchTime).toLocaleDateString('ko-KR', {
                      weekday: 'short',
                    })
                  : YOIL[request.yoil]}
              </div>
              <div className={classes['SocialRequestList__Item']}>
                {request.is_manager ? 'O' : 'X'}
              </div>
              {socialDataType === 'FIXED' && (
                <>
                  <div className={classes['SocialRequestList__Item']}>
                    {SEX[request.sex]}
                  </div>
                  <div className={classes['SocialRequestList__Item']}>
                    {LEVEL[request.level]}
                  </div>
                  <div className={classes['SocialRequestList__Item']}>
                    {request.playtime}분
                  </div>
                  <div className={classes['SocialRequestList__Item']}>
                    {request.playerCnt}명
                  </div>
                  <div className={classes['SocialRequestList__Item']}>
                    {request.excludeWeeks && `${request.excludeWeeks}주`}
                  </div>
                </>
              )}
              <div className={classes['SocialRequestList__Item']}>
                {zone?.name}
              </div>
              {socialDataType === 'INDIVIDUAL' && (
                <>
                  <div className={classes['SocialRequestList__Item']}>
                    {dDay}
                  </div>
                  <div
                    className={classes['SocialRequestList__Item']}
                  >{`${request.confirmed_apply}/${request.max_player_cnt}`}</div>
                  <div className={classes['SocialRequestList__Item']}>
                    {request.hasPenalty ? '🟨' : ''}
                  </div>
                </>
              )}

              <div
                className={`${classes['SocialRequestList__Item']} ${classes['SocialRequestList__Item--Actions']}`}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                {
                  // 구장주
                  request.requestStatus === REQUEST_STATUS['PENDING'] &&
                    authCtx.userRole !== '마스터' && (
                      <Button
                        attribute={{
                          type: 'button',
                        }}
                        classList={['Button--fit-content']}
                        onClick={() => {
                          setSelectedRequestId(request.id);
                          cancelPopUpHandler();
                        }}
                      >
                        {'요청 취소'}
                      </Button>
                    )
                }
                {
                  // 구장주
                  request.requestStatus !== REQUEST_STATUS['PENDING'] &&
                    authCtx.userRole !== '마스터' && (
                      <Button
                        attribute={{
                          type: 'button',
                        }}
                        classList={[
                          'Button--fit-content',
                          request.requestStatus === REQUEST_STATUS['APPROVED']
                            ? 'Button--green'
                            : 'Button--red',
                        ]}
                        onClick={() => {}}
                      >
                        {request.requestStatus === REQUEST_STATUS['APPROVED']
                          ? '승인됨'
                          : '반려됨'}
                      </Button>
                    )
                }
                {(request.requestStatus === REQUEST_STATUS['APPROVED'] ||
                  request.requestStatus === REQUEST_STATUS['PENDING']) &&
                  authCtx.userRole === '마스터' && (
                    <Button
                      attribute={{
                        type: 'button',
                        tabIndex:
                          request.requestStatus !== REQUEST_STATUS['PENDING']
                            ? -1
                            : null,
                      }}
                      classList={[
                        'Button--fit-content',
                        request.requestStatus !== REQUEST_STATUS['PENDING']
                          ? 'Button--Disabled'
                          : '',
                        request.requestStatus === REQUEST_STATUS['APPROVED']
                          ? 'Button--green'
                          : '',
                      ]}
                      onClick={() => {
                        setSelectedRequestId(request.id);
                        setRequestType('승인');
                        if (request.requestStatus === REQUEST_STATUS['PENDING'])
                          ApprovalAndRejectionFormOpenHandler();
                      }}
                    >
                      {request.requestStatus === REQUEST_STATUS['APPROVED']
                        ? '승인됨'
                        : '승인'}
                    </Button>
                  )}
                {(request.requestStatus === REQUEST_STATUS['REJECTED'] ||
                  request.requestStatus === REQUEST_STATUS['PENDING']) &&
                  authCtx.userRole === '마스터' && (
                    <Button
                      attribute={{
                        type: 'button',
                        tabIndex:
                          request.requestStatus !== REQUEST_STATUS['PENDING']
                            ? -1
                            : null,
                      }}
                      classList={[
                        'Button--fit-content',
                        'Button--Delete',
                        request.requestStatus !== REQUEST_STATUS['PENDING']
                          ? 'Button--Disabled'
                          : '',
                        request.requestStatus === REQUEST_STATUS['REJECTED']
                          ? 'Button--red'
                          : '',
                      ]}
                      onClick={() => {
                        setSelectedRequestId(request.id);
                        setRequestType('반려');
                        if (request.requestStatus === REQUEST_STATUS['PENDING'])
                          ApprovalAndRejectionFormOpenHandler();
                      }}
                    >
                      {request.requestStatus === REQUEST_STATUS['REJECTED']
                        ? '반려됨'
                        : '반려'}
                    </Button>
                  )}
              </div>
            </div>
          );
        })}
      </div>
      {isApprovalAndRejectionFormOpen && (
        <ApprovalAndRejectionForm
          title={requestType}
          socialDataType={socialDataType}
          socialRequestList={socialRequestList}
          onSetSocialRequestList={setSocialRequestList}
          selectedRequestId={selectedRequestId}
          onClose={ApprovalAndRejectionFormOpenHandler}
        />
      )}
      {isConfirmPopUpOpen && (
        <ConfirmPopUp
          paragraph={
            requestComment ? requestComment : '작성된 메모가 없습니다.'
          }
          onClose={confirmPopUpHandler}
        />
      )}
      {isCancelPopUpOpen && (
        <ConfirmPopUp
          paragraph={'요청을 취소하시겠습니까?'}
          onClose={cancelPopUpHandler}
          onSubmit={submitHandler}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default SocialRequestList;
