import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../../UI/Modal/Modal';
import Loader from '../../../UI/Loader/Loader';
import Select from '../../../UI/Input/Select/Select';
import Button from '../../../UI/Input/Button/Button';
import FormControlWrap from '../../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import useHttp from '../../../../hooks/use-http';
import AuthContext from '../../../../contexts/auth-context';
import classes from './SocialRequestForm.module.css';

const socialRequestReason = {
  기상악화: 'BAD_WEATHER',
  '대회/행사': 'EVENT',
  시설문제: 'FACILITY_ISSUE',
  구장직접: 'OWNER_DIRECT',
  장기대관: 'LONG_TERM_RENTAL',
  아카데미: 'ACADEMY',
  기타: 'OTHER',
};

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const SocialRequestForm = (props) => {
  const {
    title,
    socialDataType,
    onSetSocialMatchList,
    onSetSocialRequestList,
    selectedProductId,
    onClose,
  } = props;
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredReason, setEnteredReason] = useState('기타');
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  const [enteredMemo, setEnteredMemo] = useState('');
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };

  const changeMemoHandler = (evt) => {
    const value = evt.target.value;
    setEnteredMemo(value);
  };

  const changeReasonHandler = (evt) => {
    const value = evt.target.value;
    setEnteredReason(value);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialRequestList((prevState) => [data, ...prevState]);
        onSetSocialMatchList((prevState) => {
          return prevState.filter((match) => match.id !== selectedProductId);
        });
        return;
      }

      alert(data.message);
    };

    if (title === '변경 요청') {
      if (socialDataType === 'INDIVIDUAL') {
        sendSocialRequest(
          {
            urlPath: 'social-requests/individual/',
            method: 'POST',
            body: {
              request_type: 'CHANGE',
              reason: socialRequestReason[enteredReason],
              comment: enteredMemo,
              zone_id: enteredStadiumZone,
              product_id: selectedProductId,
            },
          },
          createSocialRequest
        );
      } else if (socialDataType === 'FIXED') {
        const today = new Date();
        const twoMonthsLater = adjDateFormat(
          new Date(today.getFullYear(), today.getMonth() + 2, 1, 0, 0)
        );

        sendSocialRequest(
          {
            urlPath: 'social-requests/fixed/',
            method: 'POST',
            body: {
              request_type: 'CHANGE',
              reason: socialRequestReason[enteredReason],
              comment: enteredMemo,
              zone_id: enteredStadiumZone,
              fixedMatchId: selectedProductId,
              startDate: twoMonthsLater,
            },
          },
          createSocialRequest
        );
      }
    } else if (title === '취소 요청') {
      if (socialDataType === 'INDIVIDUAL') {
        sendSocialRequest(
          {
            urlPath: 'social-requests/individual/',
            method: 'POST',
            body: {
              request_type: 'CANCEL',
              reason: socialRequestReason[enteredReason],
              comment: enteredMemo,
              product_id: selectedProductId,
            },
          },
          createSocialRequest
        );
      } else if (socialDataType === 'FIXED') {
        const today = new Date();
        const twoMonthsLater = adjDateFormat(
          new Date(today.getFullYear(), today.getMonth() + 2, 1, 0, 0)
        );

        sendSocialRequest(
          {
            urlPath: 'social-requests/fixed/',
            method: 'POST',
            body: {
              request_type: 'CANCEL',
              reason: socialRequestReason[enteredReason],
              comment: enteredMemo,
              zone_id: enteredStadiumZone,
              fixedMatchId: selectedProductId,
              startDate: twoMonthsLater,
            },
          },
          createSocialRequest
        );
      }
    }
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='product-type' className={classes.InputLabel}>
              요청 사유
            </label>
            <Select
              attribute={{
                id: 'product-type',
                value: enteredReason,
                onChange: changeReasonHandler,
              }}
              options={
                title === '변경 요청'
                  ? [
                      '대회/행사',
                      '시설문제',
                      '구장직접',
                      '장기대관',
                      '아카데미',
                      '기타',
                    ]
                  : [
                      '기상악화',
                      '대회/행사',
                      '시설문제',
                      '구장직접',
                      '장기대관',
                      '아카데미',
                      '기타',
                    ]
              }
            />
          </FormControlWrap>
        </div>
        {socialDataType === 'INDIVIDUAL' && title === '취소 요청' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor={null}
                className={`${classes.InputLabel} ${classes.hidden}`}
              ></label>
              <div>
                {enteredReason === '기상악화' ||
                enteredReason === '대회/행사' ||
                enteredReason === '시설문제' ? (
                  <>
                    불가피한 사유에 대한 증빙 자료를{' '}
                    <a
                      href='http://pf.kakao.com/_xkKxbxcxb/chat'
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes['LinkGuide']}
                    >
                      카카오톡 구장주 채널
                    </a>
                    로 제출해 주세요.
                    <br />
                    미제출 시 페널티가 부여될 수 있습니다.
                  </>
                ) : (
                  <>
                    불가피한 사유가 아닌 14일 이내 요청은 페널티가 부여될 수
                    있습니다.
                  </>
                )}
              </div>
            </FormControlWrap>
          </div>
        )}
        {title === '변경 요청' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='stadium-zone' className={classes.InputLabel}>
                구역
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'stadium-zone',
                  value: enteredStadiumZone,
                  onChange: changeStadiumZoneHandler,
                }}
                options={currentStadium.zones}
              />
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='memo'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              메모
            </label>
            <Textarea
              attribute={{
                id: 'memo',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SocialRequestForm;
