import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import FormControlWrap from '../../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Button from '../../../UI/Input/Button/Button';
import Checkbox from '../../../UI/Input/Checkbox';
import InputDate from '../../../UI/Input/InputDate';
import Select from '../../../UI/Input/Select/Select';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import Loader from '../../../UI/Loader/Loader';
import Modal from '../../../UI/Modal/Modal';
import classes from './SocialAddRequestForm.module.css';

const timeOptions = (type, enteredDate, startDate) => {
  let options = [];
  const optionDate = enteredDate
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('. ', '-')
    .slice(0, -1);

  if (type === 'start_time') {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const optionTime = `${i < 10 ? `0${i}` : i}:${j === 0 ? '00' : j * 15}`;
        options.push({
          optionVal: `${optionDate}T${optionTime}`,
          optionTxt: optionTime,
        });
      }
    }
    return options;
  }

  if (type === 'end_time') {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 4; j++) {
        if (+startDate.optionTxt.split(':')[0] + i < 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i}`
              : +startDate.optionTxt.split(':')[0] + i
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${startDate.optionVal.split('T')[0]}T${optionTime}`,
            optionTxt: optionTime,
          });
        }

        if (+startDate.optionTxt.split(':')[0] + i >= 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i - 24 < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i - 24}`
              : +startDate.optionTxt.split(':')[0] + i - 24
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${new Date(
              startDate.optionVal.split('-')[0],
              +startDate.optionVal.split('-')[1] - 1,
              +startDate.optionVal.split('-')[2].split('T')[0] + 1
            )
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replaceAll('. ', '-')
              .slice(0, -1)}T${optionTime}`,
            optionTxt: optionTime,
          });
        }
      }
    }

    if (startDate.optionTxt.split(':')[1] === '00') {
      return (options = options.slice(4, -3));
    }

    if (startDate.optionTxt.split(':')[1] === '15') {
      return (options = options.slice(5, -2));
    }

    if (startDate.optionTxt.split(':')[1] === '30') {
      return (options = options.slice(6, -1));
    }
    if (startDate.optionTxt.split(':')[1] === '45') {
      return (options = options.slice(7));
    }
  }
};

const adjDateFormat = (dateObj) => {
  let ISODateFormat;

  ISODateFormat = `${dateObj.getFullYear()}-${
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  }-${dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()}`;

  return ISODateFormat;
};

const DAYS_OF_WEEK_OPTIONS = [
  { optionVal: 'SUN', name: '일' },
  { optionVal: 'MON', name: '월' },
  { optionVal: 'TUE', name: '화' },
  { optionVal: 'WED', name: '수' },
  { optionVal: 'THU', name: '목' },
  { optionVal: 'FRI', name: '금' },
  { optionVal: 'SAT', name: '토' },
];

const YOIL = {
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
};

const SocialAddRequestForm = (props) => {
  const { title, socialDataType, onSetSocialAddRequestList, onClose } = props;
  const today = new Date();
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  const [enteredIsManger, setEnteredIsManger] = useState(false);

  let initialMonth;
  let initialDate;
  if (socialDataType === 'INDIVIDUAL') {
    initialMonth = today.getMonth();
    initialDate = today.getDate();
  } else if (socialDataType === 'FIXED') {
    initialMonth = today.getMonth() + 2;
    initialDate = 1;
  }
  const [enteredDate, setEnteredDate] = useState(
    new Date(
      today.getFullYear(),
      initialMonth,
      initialDate,
      today.getHours(),
      0
    )
  );
  const defaultStartTime = enteredDate.toLocaleTimeString('ko-KR', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [enteredStartTime, setEnteredStartTime] = useState({
    optionVal: `${adjDateFormat(enteredDate)}T${defaultStartTime}`,
    optionTxt: defaultStartTime,
  });
  const [selectedDays, setSelectedDays] = useState(
    DAYS_OF_WEEK_OPTIONS[enteredDate.getDay()].optionVal
  );
  const [enteredMemo, setEnteredMemo] = useState('');
  const { isLoading, sendRequest: sendSocialRequest } = useHttp();

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };

  const changeMemoHandler = (evt) => {
    const value = evt.target.value;
    setEnteredMemo(value);
  };

  const changeIsMangerHandler = (evt) => {
    setEnteredIsManger(evt.target.checked);
  };

  const changeDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }

    let newDate;
    if (socialDataType === 'INDIVIDUAL') {
      newDate = new Date(evt.target.value);
    } else if (socialDataType === 'FIXED') {
      const splitDate = evt.target.value.split('-');
      newDate = new Date(splitDate[0], +splitDate[1] - 1, 1);
    }

    setEnteredDate(newDate);
    setEnteredStartTime((prevState) => {
      return {
        optionVal: `${evt.target.value}T${prevState.optionTxt}`,
        optionTxt: prevState.optionTxt,
      };
    });
  };

  const changeStartTimeHandler = (evt) => {
    setEnteredStartTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });
  };

  const changeDaysHandler = (evt) => {
    setSelectedDays(evt.target.value);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    if (enteredIsManger && !enteredMemo.trim()) {
      alert('매니저 가능 시 성함과 연락처를 입력해 주세요.');
      return;
    }

    const createSocialRequest = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        onSetSocialAddRequestList((prevState) => {
          return [...prevState, data];
        });
        return;
      }

      alert(data.message);
    };

    if (socialDataType === 'INDIVIDUAL') {
      sendSocialRequest(
        {
          urlPath: 'social-requests/individual/',
          method: 'POST',
          body: {
            request_type: 'ADD',
            zone_id: enteredStadiumZone,
            comment: enteredMemo,
            is_manager: enteredIsManger,
            start_date_time: enteredStartTime.optionVal,
          },
        },
        createSocialRequest
      );
    } else if (socialDataType === 'FIXED') {
      sendSocialRequest(
        {
          urlPath: 'social-requests/fixed/',
          method: 'POST',
          body: {
            request_type: 'ADD',
            zone_id: enteredStadiumZone,
            comment: enteredMemo,
            is_manager: enteredIsManger,
            startDate: enteredStartTime.optionVal.split('T')[0],
            startTime: enteredStartTime.optionVal.split('T')[1],
            yoil: YOIL[selectedDays],
          },
        },
        createSocialRequest
      );
    }
  };

  return (
    <Modal title={title} onClose={onClose} classList={['modalSmall']}>
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='stadium-zone' className={classes.InputLabel}>
              구역
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'stadium-zone',
                value: enteredStadiumZone,
                onChange: changeStadiumZoneHandler,
              }}
              options={currentStadium.zones}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor={socialDataType === 'FIXED' ? null : 'appointment-date'}
              className={classes.InputLabel}
            >
              {socialDataType === 'INDIVIDUAL' ? '일정' : '적용 월'}
            </label>
            <InputDate
              attribute={{
                id: 'appointment-date',
                value: enteredDate,
                tabIndex: socialDataType === 'FIXED' ? -1 : null,
                onChange: changeDateHandler,
              }}
              visibleText={socialDataType === 'FIXED' && 'month'}
              classList={socialDataType === 'FIXED' ? ['disabled'] : []}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor='appointment-time'
              className={`${classes.InputLabel} ${classes.hidden}`}
            >
              시간
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'appointment-time',
                value: enteredStartTime.optionVal,
                onChange: changeStartTimeHandler,
              }}
              options={timeOptions('start_time', enteredDate)}
            />
          </FormControlWrap>
        </div>
        {socialDataType === 'FIXED' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='stadium-zone' className={classes.InputLabel}>
                요일
              </label>
              <Select
                selectType='object'
                attribute={{
                  id: 'stadium-zone',
                  value: selectedDays,
                  onChange: changeDaysHandler,
                }}
                options={DAYS_OF_WEEK_OPTIONS}
              />
            </FormControlWrap>
          </div>
        )}

        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='is-manager' className={classes.InputLabel}>
              매니저
            </label>
            <Checkbox
              attribute={{
                name: 'is-manager',
                onChange: changeIsMangerHandler,
                defaultChecked: enteredIsManger,
              }}
            />
          </FormControlWrap>
        </div>
        {enteredIsManger && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label
                htmlFor='is-manager'
                className={`${classes.InputLabel} ${classes.hidden}`}
              ></label>
              <div>매니저 가능 시 성함과 연락처를 입력해 주세요.</div>
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['align--flex-start']}>
            <label
              htmlFor='memo'
              className={`${classes.InputLabel} ${classes.textarea}`}
            >
              메모
            </label>
            <Textarea
              attribute={{
                id: 'memo',
                value: enteredMemo,
                onChange: changeMemoHandler,
                placeholder: '메모',
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SocialAddRequestForm;
