import DayScaleCell from '../DayScaleCell/DayScaleCell';
import classes from './DayScaleRow.module.css';

const DayScaleRow = (props) => {
  const { schedulerState } = props;
  const daysOfWeek = schedulerState.date.slice(0, 7);

  return (
    <div className={classes.DayScaleRow}>
      {daysOfWeek.map((date, idx) => (
        <DayScaleCell key={idx} date={date} {...props} />
      ))}
    </div>
  );
};

export default DayScaleRow;
