import TimeTableRow from './TimeTableRow/TimeTableRow';
import classes from './TimeTableLayout.module.css';

const TimeTableLayout = (props) => {
  const { schedulerState } = props;
  const splitSchedulerDate = [];

  for (let i = 0; i < schedulerState.date.length; i += 7) {
    splitSchedulerDate.push(schedulerState.date.slice(i, i + 7));
  }

  const timeTableRows = Array(6)
    .fill()
    .map((row, idx) => (
      <TimeTableRow key={idx} daysOfWeek={splitSchedulerDate[idx]} {...props} />
    ));

  return <div className={classes.TimeTableLayout}>{timeTableRows}</div>;
};

export default TimeTableLayout;
