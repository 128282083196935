import { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Xmark from '../Icons/Bars/Xmark';
import classes from './Modal.module.css';

const Backdrop = (props) => {
  return <div className={classes.Backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const {
    classList = [],
    onClose,
    hasHeader = true,
    title,
    paragraph = null,
  } = props;
  const className = `${classes.ModalOverlay} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  document.body.style.overflow = 'hidden';

  useEffect(() => {
    return () => {
      if (!document.getElementById('overlays').children.length) {
        document.body.style.overflow = 'visible';
      }
    };
  }, []);

  return (
    <Fragment>
      <Backdrop onClose={onClose} />
      <section className={className}>
        {hasHeader && (
          <header className={classes['ModalOverlay__Header']}>
            <div className={classes['ModalOverlay__HeaderInner']}>
              <h2 className={classes['ModalOverlay__Title']}>{title}</h2>
              {paragraph && (
                <p className={classes['ModalOverlay__Paragraph']}>
                  {paragraph}
                </p>
              )}
              <button
                type='button'
                className={classes['ModalOverlay__CloseBtn']}
                onClick={onClose}
              >
                <Xmark />
              </button>
            </div>
          </header>
        )}
        <div className={classes['ModalOverlay__Body']}>{props.children}</div>
      </section>
    </Fragment>
  );
};

const Modal = (props) => {
  return ReactDOM.createPortal(
    <ModalOverlay {...props}>{props.children}</ModalOverlay>,
    document.getElementById('overlays')
  );
};

export default Modal;
