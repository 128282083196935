import CommonLayout from '../../components/layouts/CommonLayout';
import ZoneList from '../../components/Scheduler/ZoneList/ZoneList';
import SocialRequest from '../../components/SocialRequest/SocialRequest';

const SocialRequests = () => {
  return (
    <CommonLayout>
      <ZoneList />
      <SocialRequest />
    </CommonLayout>
  );
};

export default SocialRequests;
