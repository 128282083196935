import classes from './FormControlWrap.module.css';

const FormControlWrap = (props) => {
  const { classList = [] } = props;
  const className = `${classes.FormControlWrap} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return <div className={className}>{props.children}</div>;
};

export default FormControlWrap;
