import { forwardRef } from 'react';

import classes from './InputRadio.module.css';

const InputRadio = forwardRef((props, ref) => {
  const { attribute, tip, classList = [] } = props;
  const className = `${classes.InputRadio} ${classList
    .map((item) => classes[item])
    .join(' ')}`;

  return (
    <label htmlFor={props.attribute.id} className={className}>
      <input
        type='radio'
        {...attribute}
        ref={ref}
        className={classes['InputRadio__Input']}
      />
      <div className={classes['InputRadio__Text']}>
        {props.children}
        {tip && <div className={classes['InputRadio__Tip']}>{tip}</div>}
      </div>
    </label>
  );
});

export default InputRadio;
