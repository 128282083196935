import { Fragment } from 'react';
import SchedulerBody from '../SchedulerLayout/SchedulerBody/SchedulerBody';
import DayScaleLayout from '../DayScaleLayout/DayScaleLayout';
import TimeTableLayout from './TimeTableLayout/TimeTableLayout';
import TimeScaleLayout from './TimeScaleLayout/TimeScaleLayout';

const WeekView = (props) => {
  if (props.schedulerState.viewState === 'month') return null;
  return (
    <Fragment>
      <DayScaleLayout {...props} />
      <SchedulerBody>
        <TimeScaleLayout {...props} />
        <TimeTableLayout {...props} />
      </SchedulerBody>
    </Fragment>
  );
};

export default WeekView;
