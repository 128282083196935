import classes from './DateDisplay.module.css';

const DateDisplay = (props) => {
  const { schedulerState, onChangeSchedulerState, adjDateFormat } = props;

  const changeCurDateHandler = (evt) => {
    if (!evt.target.value) return;
    if (schedulerState.viewState === 'month') {
      onChangeSchedulerState({
        type: 'CUR_MONTH',
        date: new Date(evt.target.value),
      });
    }

    if (schedulerState.viewState === 'week') {
      onChangeSchedulerState({
        type: 'CUR_WEEK',
        date: new Date(evt.target.value),
      });
    }
  };

  let dateDisplay = '';

  if (schedulerState.viewState === 'month') {
    dateDisplay = schedulerState.date[6].toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'short',
    });
  }

  if (schedulerState.viewState === 'week') {
    dateDisplay = schedulerState.date[0].toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'short',
    });
  }

  return (
    <div className={classes.DateDisplay}>
      <strong className={classes['DateDisplay__Date']}>
        {dateDisplay}
        <input
          type='date'
          value={adjDateFormat(schedulerState.date[6])}
          onChange={changeCurDateHandler}
          tabIndex='-1'
          className={classes['DateDisplay__InputDate']}
        />
      </strong>
    </div>
  );
};

export default DateDisplay;
