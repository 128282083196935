import React, { useContext, useState } from 'react';

import AuthContext from '../../../contexts/auth-context';
import Button from '../../UI/Input/Button/Button';
import classes from './SocialMatchList.module.css';
import SocialRequestForm from './SocialRequestForm/SocialRequestForm';

const socialMatchStatus = {
  CONFIRMED: '공개',
  RELEASE: '공개',
  READY: '대기',
  NOT_CONFIRMED_CAN_NOT_RENT: '대기',
  NOT_CONFIRMED_CAN_RENT: '대기',
};
const yoil = {
  0: '월요일',
  1: '화요일',
  2: '수요일',
  3: '목요일',
  4: '금요일',
  5: '토요일',
  6: '일요일',
};
const SEX = {
  '-1': '여성매치',
  0: '남녀 모두 매치',
  1: '남성매치',
};
const LEVEL = {
  0: '누구나',
  1: '초급',
  2: '중급',
  3: '아마추어1 이하',
  4: '아마추어2 이하',
  5: '아마추어2 이상',
  6: '세미프로1 이상',
};

const SocialMatchList = (props) => {
  const {
    socialDataType,
    socialMatchList,
    setSocialMatchList,
    setSocialRequestList,
  } = props;
  const authCtx = useContext(AuthContext);

  const [isSocialRequestFormOpen, setIsSocialRequestFormOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const socialRequestFormOpenHandler = () => {
    setIsSocialRequestFormOpen((prevState) => !prevState);
  };
  const [requestType, setRequestType] = useState(null);

  return (
    <>
      <div className={classes['SocialMatchList']}>
        <h2 className={classes['SocialMatchList__Title']}>
          {socialDataType === 'INDIVIDUAL' ? '개별일정' : '정기일정'}
        </h2>
        <div className={classes['SocialMatchList__ItemBox']}>
          {socialDataType === 'INDIVIDUAL' && (
            <div
              className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
            >
              상태
            </div>
          )}
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            {socialDataType === 'INDIVIDUAL' ? '일시' : '타임'}
          </div>
          <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            요일
          </div>
          {socialDataType === 'INDIVIDUAL' && (
            <div
              className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
            >
              D-Day
            </div>
          )}
          {/* 플랩 매치 정보 쿼리 성능 개선 후 다시 노출 필요 */}
          {/* <div
            className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
          >
            매니저 여부
          </div>
          {socialDataType === 'INDIVIDUAL' && (
            <div
              className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
            >
              신청 현황
            </div>
          )} */}
          {socialDataType === 'FIXED' && (
            <>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                매니저
              </div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                성별
              </div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                레벨
              </div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                플레이 타임
              </div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                플레이어
              </div>
              <div
                className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Title']} `}
              >
                제외
              </div>
            </>
          )}
        </div>
        {socialMatchList?.map((match, idx) => {
          let matchTime;
          let dDay;
          if (socialDataType === 'INDIVIDUAL') {
            const date = match?.time?.startTime?.split('T')[0];
            const time = match?.time?.startTime?.split('T')[1];
            matchTime = `${date} ${time}`;
            dDay = `D-${Math.floor(
              (new Date(match?.time?.startTime).setHours(0, 0, 0, 0) -
                new Date().setHours(0, 0, 0, 0)) /
                (1000 * 60 * 60 * 24)
            )}`;
          } else if (socialDataType === 'FIXED') {
            matchTime = match.startTime;
          }
          return (
            <>
              <div
                key={match.id}
                className={classes['SocialMatchList__ItemBox']}
              >
                {socialDataType === 'INDIVIDUAL' && (
                  <div className={classes['SocialMatchList__Item']}>
                    {socialMatchStatus[match.socialMatchStatus]}
                  </div>
                )}
                <div className={classes['SocialMatchList__Item']}>
                  {matchTime}
                </div>
                <div className={classes['SocialMatchList__Item']}>
                  {socialDataType === 'INDIVIDUAL'
                    ? new Date(match?.time?.startTime).toLocaleDateString(
                        'ko-KR',
                        {
                          weekday: 'short',
                        }
                      )
                    : yoil[match.yoil]}
                </div>
                {socialDataType === 'INDIVIDUAL' && (
                  <div className={classes['SocialMatchList__Item']}>{dDay}</div>
                )}
                {socialDataType === 'FIXED' && (
                  <>
                    <div className={classes['SocialMatchList__Item']}>
                      {match.is_manager ? 'O' : 'X'}
                    </div>
                    <div className={classes['SocialMatchList__Item']}>
                      {SEX[match.sex]}
                    </div>
                    <div className={classes['SocialMatchList__Item']}>
                      {LEVEL[match.level]}
                    </div>
                    <div className={classes['SocialMatchList__Item']}>
                      {match.playtime}분
                    </div>
                    <div className={classes['SocialMatchList__Item']}>
                      {match.playerCnt}명
                    </div>
                    <div className={classes['SocialMatchList__Item']}>
                      {match.excludeWeeks && `${match.excludeWeeks}주`}
                    </div>
                  </>
                )}
                {/* <div className={classes['SocialMatchList__Item']}>
                {match.is_manager ? 'O' : 'X'}
              </div>
              {socialDataType === 'INDIVIDUAL' && (
                <div
                  className={classes['SocialMatchList__Item']}
                >{`${match.confirmed_apply}/${match.max_player_cnt}`}</div>
              )} */}
                {authCtx.userRole !== '마스터' && (
                  <>
                    <div
                      className={`${classes['SocialMatchList__Item']} ${classes['SocialMatchList__Item--Actions']}`}
                    >
                      <Button
                        attribute={{ type: 'button' }}
                        classList={['Button--fit-content']}
                        onClick={() => {
                          setSelectedProductId(match.id);
                          setRequestType('변경 요청');
                          socialRequestFormOpenHandler();
                        }}
                      >
                        변경 요청
                      </Button>
                      {!(
                        socialDataType === 'INDIVIDUAL' &&
                        socialMatchStatus[match.socialMatchStatus] === '대기'
                      ) && (
                        <Button
                          attribute={{ type: 'button' }}
                          classList={['Button--fit-content']}
                          onClick={() => {
                            setSelectedProductId(match.id);
                            setRequestType('취소 요청');
                            socialRequestFormOpenHandler();
                          }}
                        >
                          취소 요청
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
              {socialMatchList.length - 1 !== idx &&
                socialMatchList[idx].yoil !== socialMatchList[idx + 1].yoil && (
                  <hr key={idx} />
                )}
            </>
          );
        })}
      </div>
      {isSocialRequestFormOpen && (
        <SocialRequestForm
          title={requestType}
          socialDataType={socialDataType}
          onSetSocialMatchList={setSocialMatchList}
          onSetSocialRequestList={setSocialRequestList}
          selectedProductId={selectedProductId}
          onClose={socialRequestFormOpenHandler}
        />
      )}
    </>
  );
};

export default SocialMatchList;
