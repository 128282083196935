import { Fragment, useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import Header from './Header/Header';
import Main from './Main/Main';
import Aside from './Aside/Aside';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import GlobalNav from '../GlobalNav/GlobalNav';
import AuthContext from '../../contexts/auth-context';
import ChannelService from '../ChannelTalk/ChannelService/ChannelService';

const CommonLayout = (props) => {
  const authCtx = useContext(AuthContext);
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);
  const [isAsideOpen, setIsAsideOpen] = useState(false);

  const headerOpenHandler = (isOpen) => {
    setIsHeaderOpen(isOpen);
  };

  useEffect(() => {
    const channelTalk = new ChannelService();
    if (authCtx.isLoggedIn) {
      channelTalk.boot({
        pluginKey: '51c69b67-2a38-4099-a7d0-71151e22539e',
        zIndex: 200,
      });
    }

    return () => {
      channelTalk.shutdown();
    };
  }, [authCtx.isLoggedIn]);

  if (!authCtx.isLoggedIn) {
    return <Redirect to='/login' />;
  }

  return (
    <Fragment>
      <Header isHeaderOpen={isHeaderOpen} onHeaderOpen={headerOpenHandler}>
        <HeaderTitle />
      </Header>
      <Main>{props.children}</Main>
      <Aside
        isAsideOpen={isAsideOpen}
        onAsideOpen={setIsAsideOpen}
        isHeaderOpen={isHeaderOpen}
      >
        <GlobalNav isAsideOpen={isAsideOpen} onAsideOpen={setIsAsideOpen} />
      </Aside>
    </Fragment>
  );
};

export default CommonLayout;
