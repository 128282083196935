import { useState, useEffect, useRef } from 'react';
import classes from './Header.module.css';

const Header = (props) => {
  const { isHeaderOpen, onHeaderOpen } = props;
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const headerRef = useRef();

  useEffect(() => {
    const scrollHandler = () => {
      let moving = window.pageYOffset;
      const headerHeight = headerRef.current.offsetHeight;

      if (window.pageYOffset < headerHeight) {
        onHeaderOpen(true);
        return;
      }
      onHeaderOpen(scrollPosition > moving);
      setScrollPosition(moving);
    };
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [onHeaderOpen, scrollPosition]);

  return (
    <header
      className={`${classes.Header} ${isHeaderOpen ? '' : classes.hidden}`}
      ref={headerRef}
    >
      {props.children}
    </header>
  );
};

export default Header;
