import { useState } from 'react';
import AppointmentEditForm from '../../Forms/AppointmentEditForm/AppointmentEditForm';
import classes from './Appointment.module.css';

const Appointment = (props) => {
  const { data, filteredTypes, onChangeFilteredTypes } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  let startTime;

  if (!data.splittedBlock) {
    startTime = data.time.startTime.split('T')[1];
  } else {
    startTime = data.splittedBlock.startTime.split('T')[1];
  }

  const endTime = data.time.endTime.split('T')[1];

  let playTime;

  if (!data.splittedBlock) {
    playTime =
      (new Date(data.time.endTime) - new Date(data.time.startTime)) /
      1000 /
      60 /
      60;
  } else {
    if (!data.lastBlock) {
      playTime =
        (new Date(data.time.endTime) - new Date(data.splittedBlock.startTime)) /
        1000 /
        60 /
        60;
    } else {
      playTime =
        (new Date(data.splittedBlock.endTime) -
          new Date(data.splittedBlock.startTime)) /
        1000 /
        60 /
        60;
    }
  }

  const formOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  const startPoint = `calc(${
    (+startTime.split(':')[1] / 60) * 100
  }% + var(--scheduler-table-cell-padding) )`;
  const heightOfOneBlock =
    '(var(--scheduler-weekview-timetable-cell-height) - var(--scheduler-table-cell-padding))';
  const rowGap = '(calc(var(--scheduler-table-cell-padding) * 2))';
  const totalHeight = `calc(
    ${heightOfOneBlock} * ${playTime} + ${rowGap} * ${playTime - 1}
  )`;

  let productType = data.productType;
  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_RENT') {
    productType = '소셜대기/대관가능';
  }

  if (data.socialMatchStatus === 'NOT_CONFIRMED_CAN_NOT_RENT') {
    productType = '소셜대기/대관불가';
  }

  const classList = `${classes.Appointment} ${
    playTime === 1
      ? classes.oneHour
      : playTime === 1.5
      ? classes.oneHourHalf
      : playTime === 0.5
      ? classes.halfHour
      : ''
  } ${!filteredTypes.includes(productType) ? classes.hidden : ''} ${
    data.splittedBlock ? classes.splittedBlock : ''
  } ${
    data.isOriginalOfSplittedBlock && +startTime.split(':')[0] >= 4
      ? classes.isOriginalOfSplittedBlock
      : ''
  } ${+startTime.split(':')[0] === 5 ? classes.hidePrice : ''} ${
    playTime * 100 <= 25 ? classes.hideInfo : ''
  }`;

  return (
    <div
      className={classList}
      data-product-type={productType}
      data-cancel-status={data.matchStatus}
      style={{
        top: startPoint,
        height: totalHeight,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
    >
      <div
        className={classes['Appointment__Inner']}
        onClick={() => {
          if (!filteredTypes.includes(productType)) {
            return onChangeFilteredTypes(productType);
          }
          formOpenHandler();
        }}
      >
        {!data.splittedBlock && (
          <div className={classes['Appointment__Time']}>
            <span className={classes['Appointment__StartTime']}>
              {startTime}
            </span>
            <span className={classes['Appointment__Time--Tilde']}>~</span>
            <span className={classes['Appointment__EndTime']}>{endTime}</span>
          </div>
        )}
        {productType !== '예약가능' && (
          <div className={classes['Appointment__Consumer']}>
            {productType !== '예약불가' && data.consumer}
            {productType === '예약불가' && '예약불가'}
          </div>
        )}
        {productType === '소셜매치' && data.matchApplyCnt !== undefined && (
          <div>
            {data.matchApplyCnt}/{data.maxPlayerCnt}
          </div>
        )}
        {productType !== '소셜매치' && productType !== '소셜대기/대관불가' && (
          <div className={classes['Appointment__Price']}>
            {Boolean(data.price) && `${data.price.toLocaleString('ko-KR')}원`}
          </div>
        )}
      </div>
      {isFormOpen && (
        <AppointmentEditForm {...props} onClose={formOpenHandler} />
      )}
    </div>
  );
};

export default Appointment;
