import { Fragment } from 'react';

import HamburgerBtn from './HamburgerBtn/HamburgerBtn';
import classes from './Aside.module.css';

const Aside = (props) => {
  const { isAsideOpen, onAsideOpen, isHeaderOpen } = props;
  if (isAsideOpen && isHeaderOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <Fragment>
      <HamburgerBtn isHeaderOpen={isHeaderOpen} onClick={onAsideOpen} />
      <aside
        className={`${classes.Aside} ${
          isAsideOpen && isHeaderOpen ? classes.show : ''
        }`}
      >
        <div
          className={classes['Aside_Backdrop']}
          onClick={() => onAsideOpen(false)}
        />
        <div className={classes['Aside__Inner']}>{props.children}</div>
      </aside>
    </Fragment>
  );
};

export default Aside;
