import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Modal from '../../../UI/Modal/Modal';
import Loader from '../../../UI/Loader/Loader';
import FormControlWrap from '../FormControlWrap/FormControlWrap';
import Select from '../../../UI/Input/Select/Select';
import InputDate from '../../../UI/Input/InputDate';
import ToggleBtn from '../../../UI/Input/ToggleBtn/ToggleBtn';
import Checkbox from '../../../UI/Input/Checkbox';
import CheckboxCircle from '../../../UI/Input/CheckboxCircle';
import InputRadio from '../../../UI/Input/InputRadio';
import InputPrice from '../../../UI/Input/InputPrice';
import InputText from '../../../UI/Input/InputText';
import Textarea from '../../../UI/Input/Textarea/Textarea';
import Button from '../../../UI/Input/Button/Button';
import ConflictedSchedulePopUp from '../../PopUps/ConflictedSchedulePopUp/ConflictedSchedulePopUp';
import AuthContext from '../../../../contexts/auth-context';
import useHttp from '../../../../hooks/use-http';
import classes from './AppointmentForm.module.css';

const timeOptions = (type, enteredDate, startDate) => {
  let options = [];
  const optionDate = enteredDate
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('. ', '-')
    .slice(0, -1);

  if (type === 'start_time') {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const optionTime = `${i < 10 ? `0${i}` : i}:${j === 0 ? '00' : j * 15}`;
        options.push({
          optionVal: `${optionDate}T${optionTime}`,
          optionTxt: optionTime,
        });
      }
    }
    return options;
  }

  if (type === 'end_time') {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 4; j++) {
        if (+startDate.optionTxt.split(':')[0] + i < 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i}`
              : +startDate.optionTxt.split(':')[0] + i
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${startDate.optionVal.split('T')[0]}T${optionTime}`,
            optionTxt: optionTime,
          });
        }

        if (+startDate.optionTxt.split(':')[0] + i >= 24) {
          const optionTime = `${
            +startDate.optionTxt.split(':')[0] + i - 24 < 10
              ? `0${+startDate.optionTxt.split(':')[0] + i - 24}`
              : +startDate.optionTxt.split(':')[0] + i - 24
          }:${j === 0 ? '00' : j * 15}`;

          options.push({
            optionVal: `${new Date(
              startDate.optionVal.split('-')[0],
              +startDate.optionVal.split('-')[1] - 1,
              +startDate.optionVal.split('-')[2].split('T')[0] + 1
            )
              .toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              .replaceAll('. ', '-')
              .slice(0, -1)}T${optionTime}`,
            optionTxt: optionTime,
          });
        }
      }
    }

    if (startDate.optionTxt.split(':')[1] === '00') {
      return (options = options.slice(4, -3));
    }

    if (startDate.optionTxt.split(':')[1] === '15') {
      return (options = options.slice(5, -2));
    }

    if (startDate.optionTxt.split(':')[1] === '30') {
      return (options = options.slice(6, -1));
    }
    if (startDate.optionTxt.split(':')[1] === '45') {
      return (options = options.slice(7));
    }
  }
};

const DAYS_OF_WEEK_OPTIONS = [
  { dayId: 'opt-sun', day: '일' },
  { dayId: 'opt-mon', day: '월' },
  { dayId: 'opt-tue', day: '화' },
  { dayId: 'opt-wed', day: '수' },
  { dayId: 'opt-thu', day: '목' },
  { dayId: 'opt-fri', day: '금' },
  { dayId: 'opt-sat', day: '토' },
];

const AppointmentForm = (props) => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const { isLoading, sendRequest: sendAppointmentRequest } = useHttp();
  const {
    adjDateFormat,
    onChangeSchedulerState,
    schedulerState,
    date,
    onClose,
  } = props;
  const currentStadium = authCtx.stadiumsList.find(
    (stadium) => +stadium.id === +params.stadiumId
  );
  const [enteredProductType, setEnteredProductType] = useState('');
  const [enteredStadiumZone, setEnteredStadiumZone] = useState(+params.zoneId);
  const [enteredSocialStatus, setEnteredSocialStatus] =
    useState('social-standby');
  const [enteredPlabRentalOpt, setEnteredPlabRentalOpt] = useState(true);
  const [enteredDate, setEnteredDate] = useState(date);
  const defaultStartTime = date.toLocaleTimeString('ko-KR', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [enteredStartTime, setEnteredStartTime] = useState({
    optionVal: `${adjDateFormat(date)}T${defaultStartTime}`,
    optionTxt: defaultStartTime,
  });

  const initializedEndTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours() + 2,
    date.getMinutes()
  );
  const defaultEndTime = initializedEndTime.toLocaleTimeString('ko-KR', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
  const [enteredEndTime, setEnteredEndTime] = useState({
    optionVal: `${adjDateFormat(initializedEndTime)}T${defaultEndTime}`,
    optionTxt: defaultEndTime,
  });
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedDays, setSelectedDays] = useState([enteredDate.getDay()]);
  const [enteredEndDate, setEnteredEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
  );
  const [selectedRepeatOpt, setSelectedRepeatOpt] = useState('end-date');
  const [enteredPrice, setEnteredPrice] = useState(100000);
  const [enteredName, setEnteredName] = useState(null);
  const [enteredPhoneNum, setEnteredPhoneNum] = useState(null);
  const [enteredNote, setEnteredNote] = useState(null);
  const [conflictedSchedulePopUp, setConflictedSchedulePopUp] = useState({
    data: [],
    isOpen: false,
  });

  const changeProductTypeHandler = (evt) => {
    setEnteredProductType(evt.target.value);
  };

  const changeStadiumZoneHandler = (evt) => {
    setEnteredStadiumZone(evt.target.value);
  };

  const changeSocialStatusHandler = (evt) => {
    setEnteredSocialStatus(evt.target.id);
  };

  const changePlabRentalOptHandler = (evt) => {
    setEnteredPlabRentalOpt(evt.target.checked);
  };

  const changeDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }
    setEnteredDate(new Date(evt.target.value));
    const dayOfWeek = new Date(evt.target.value).getDay();
    setSelectedDays([dayOfWeek]);
    setEnteredStartTime((prevState) => {
      setEnteredEndTime((endTimePrevState) => {
        if (
          endTimePrevState.optionVal.split('T')[0] ===
          prevState.optionVal.split('T')[0]
        ) {
          return {
            optionVal: `${evt.target.value}T${endTimePrevState.optionTxt}`,
            optionTxt: endTimePrevState.optionTxt,
          };
        }

        if (
          endTimePrevState.optionVal.split('T')[0] !==
          prevState.optionVal.split('T')[0]
        ) {
          const enteredDateObj = new Date(evt.target.value);
          const adjEndDate = adjDateFormat(
            new Date(
              enteredDateObj.getFullYear(),
              enteredDateObj.getMonth(),
              enteredDateObj.getDate() + 1
            )
          );

          return {
            optionVal: `${adjEndDate}T${endTimePrevState.optionTxt}`,
            optionTxt: endTimePrevState.optionTxt,
          };
        }
      });

      return {
        optionVal: `${evt.target.value}T${prevState.optionTxt}`,
        optionTxt: prevState.optionTxt,
      };
    });
  };

  const changeStartTimeHandler = (evt) => {
    setEnteredStartTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });

    const timeGap =
      (new Date(enteredEndTime.optionVal) -
        new Date(enteredStartTime.optionVal)) /
      1000 /
      60 /
      60;
    const endDateObj = new Date(evt.target.value);
    const adjEndTimeObj = new Date(
      endDateObj.getFullYear(),
      endDateObj.getMonth(),
      endDateObj.getDate(),
      endDateObj.getHours() + Math.floor(timeGap),
      (endDateObj.getMinutes() / 60 + (timeGap % 1)) * 60
    );
    const adjEndTime = adjEndTimeObj.toLocaleTimeString('ko-KR', {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    });
    setEnteredEndTime({
      optionVal: `${adjDateFormat(adjEndTimeObj)}T${adjEndTime}`,
      optionTxt: adjEndTime,
    });
  };

  const changeEndTimeHandler = (evt) => {
    setEnteredEndTime({
      optionVal: evt.target.value,
      optionTxt: evt.target.value.split('T')[1],
    });
  };

  const changeIsRepeatHandler = () => {
    setIsRepeat((prevState) => !prevState);
  };

  const changeSelectedDaysHandler = (position) => {
    setSelectedDays((prevState) => {
      if (prevState.includes(position)) {
        return prevState.filter((day) => day !== position);
      } else {
        return [...prevState, position];
      }
    });
  };

  const changeEndDateHandler = (evt) => {
    if (evt.target.value === '') {
      return;
    }
    setEnteredEndDate(new Date(evt.target.value));
  };

  const changeSelectedRepeatOptHandler = (evt) => {
    setSelectedRepeatOpt(evt.target.id);
  };

  const changePriceHandler = (evt) => {
    setEnteredPrice(Number(evt.target.value.replaceAll(',', '')));
  };

  const changeNameHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredName(value);
  };

  const changePhoneNumHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredPhoneNum(value);
  };

  const changeNoteHandler = (evt) => {
    const value = evt.target.value.trim() ? evt.target.value : null;
    setEnteredNote(value);
  };

  const conflictedPopUpHandler = (conflictedSchedule) => {
    setConflictedSchedulePopUp((prevState) => ({
      data: conflictedSchedule ? conflictedSchedule : null,
      isOpen: !prevState.isOpen,
    }));
  };

  const submitHandler = (evt) => {
    evt.preventDefault();
    const adjustedDate = adjDateFormat(enteredDate);
    const adjustedEndDate = adjDateFormat(enteredEndDate);
    let socialStatus;
    if (enteredSocialStatus === 'social-public') {
      socialStatus = 'CONFIRMED';
    }

    if (enteredSocialStatus === 'social-standby') {
      if (enteredPlabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_RENT';
      }

      if (!enteredPlabRentalOpt) {
        socialStatus = 'NOT_CONFIRMED_CAN_NOT_RENT';
      }
    }
    const adjPrice =
      (enteredProductType !== '예약불가' &&
        enteredProductType !== '소셜매치') ||
      (enteredProductType === '소셜매치' &&
        enteredSocialStatus === 'social-standby' &&
        enteredPlabRentalOpt)
        ? enteredPrice
        : null;
    const adjName =
      enteredProductType !== '예약가능' && enteredProductType !== '예약불가'
        ? enteredName
        : null;
    const adjPhoneNum =
      enteredProductType !== '소셜매치' &&
      enteredProductType !== '예약가능' &&
      enteredProductType !== '예약불가'
        ? enteredPhoneNum
        : null;
    const adjNote = enteredProductType !== '예약가능' ? enteredNote : null;

    const updateScheduler = async (res) => {
      const data = await res.json();

      if (res.ok) {
        onClose();
        if (schedulerState.viewState === 'month') {
          onChangeSchedulerState({
            type: 'CUR_MONTH',
            date: enteredDate,
          });
        } else if (schedulerState.viewState === 'week') {
          onChangeSchedulerState({
            type: 'CUR_WEEK',
            date: enteredDate,
          });
        }
        return;
      }

      switch (data.code) {
        case 'product_overlapped':
          conflictedPopUpHandler(data.conflictedSchedule);
          break;
        default:
          alert(data.message);
      }
    };

    if (!isRepeat) {
      sendAppointmentRequest(
        {
          urlPath: 'products',
          method: 'POST',
          body: {
            productType: enteredProductType,
            socialMatchStatus:
              enteredProductType === '소셜매치' ? socialStatus : null,
            date: adjustedDate,
            time: {
              startTime: enteredStartTime.optionVal,
              endTime: enteredEndTime.optionVal,
            },
            price: adjPrice,
            consumer: adjName,
            phoneNum: adjPhoneNum,
            note: adjNote,
            zoneId: enteredStadiumZone,
          },
        },
        updateScheduler
      );
    }

    if (isRepeat) {
      sendAppointmentRequest(
        {
          urlPath: 'products/weeks',
          method: 'POST',
          body: {
            productType: enteredProductType,
            socialMatchStatus:
              enteredProductType === '소셜매치' ? socialStatus : null,
            date: adjustedDate,
            time: {
              startTime: enteredStartTime.optionVal,
              endTime: enteredEndTime.optionVal,
            },
            repeatOpt: {
              type: 'weeks',
              daysOfWeek: selectedDays,
              endDate:
                selectedRepeatOpt === 'end-date' ? adjustedEndDate : null,
            },
            price: adjPrice,
            consumer: adjName,
            phoneNum: adjPhoneNum,
            note: adjNote,
            zoneId: enteredStadiumZone,
          },
        },
        updateScheduler
      );
    }
  };

  let selectedDaysOptions = DAYS_OF_WEEK_OPTIONS.map((dayOfWeek, idx) => (
    <CheckboxCircle
      key={dayOfWeek.dayId}
      attribute={{
        name: 'repeat-day',
        id: dayOfWeek.dayId,
        checked: selectedDays.includes(idx) ? true : false,
        onChange: () => changeSelectedDaysHandler(idx),
        disabled: enteredDate.getDay() === idx ? true : false,
        tabIndex: enteredDate.getDay() === idx ? '-1' : '',
      }}
    >
      {dayOfWeek.day}
    </CheckboxCircle>
  ));

  if (schedulerState.firstDayOfWeek === 'mon') {
    const sunday = selectedDaysOptions.slice(0, 1);
    selectedDaysOptions = DAYS_OF_WEEK_OPTIONS.map((dayOfWeek, idx) => (
      <CheckboxCircle
        key={dayOfWeek.dayId}
        attribute={{
          name: 'repeat-day',
          id: dayOfWeek.dayId,
          checked: selectedDays.includes(idx) ? true : false,
          onChange: () => changeSelectedDaysHandler(idx),
          disabled: enteredDate.getDay() === idx ? true : false,
          tabIndex: enteredDate.getDay() === idx ? '-1' : '',
        }}
      >
        {dayOfWeek.day}
      </CheckboxCircle>
    ))
      .slice(1)
      .concat(sunday);
  }

  return (
    <Modal
      title='일정 등록'
      onClose={onClose}
      classList={
        conflictedSchedulePopUp.isOpen
          ? ['hidden', 'fullScreen']
          : ['fullScreen']
      }
    >
      <form className={classes.AppointmentForm} onSubmit={submitHandler}>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='product-type' className={classes.InputLabel}>
              타입
            </label>
            <Select
              attribute={{
                id: 'product-type',
                value: enteredProductType,
                onChange: changeProductTypeHandler,
              }}
              options={
                authCtx.userRole !== '마스터'
                  ? ['구장직접', '장기대관', '아카데미', '예약가능', '예약불가']
                  : [
                      '구장직접',
                      '장기대관',
                      '아카데미',
                      '소셜매치',
                      '예약가능',
                      '예약불가',
                    ]
              }
              classList={[enteredProductType === '' ? 'has-placeholder' : '']}
              placeholder='대관타입'
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='stadium-zone' className={classes.InputLabel}>
              구역
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'stadium-zone',
                value: enteredStadiumZone,
                onChange: changeStadiumZoneHandler,
              }}
              options={currentStadium.zones}
            />
          </FormControlWrap>
        </div>
        {authCtx.userRole === '마스터' && enteredProductType === '소셜매치' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label className={classes.InputLabel}>상태</label>
              <InputRadio
                attribute={{
                  id: 'social-standby',
                  name: 'social-status',
                  onChange: changeSocialStatusHandler,
                  defaultChecked: true,
                }}
              >
                대기
              </InputRadio>
              <InputRadio
                attribute={{
                  id: 'social-public',
                  name: 'social-status',
                  onChange: changeSocialStatusHandler,
                }}
              >
                공개
              </InputRadio>
            </FormControlWrap>
          </div>
        )}
        {authCtx.userRole === '마스터' && enteredProductType === '소셜매치' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label className={`${classes.InputLabel} ${classes.hidden}`}>
                플랩대관 가능 여부
              </label>
              <Checkbox
                attribute={{
                  name: 'plab-rental-option',
                  onChange: changePlabRentalOptHandler,
                  defaultChecked: true,
                  tabIndex: enteredSocialStatus === 'social-public' ? null : '',
                  disabled:
                    enteredSocialStatus === 'social-public' ? true : false,
                }}
              >
                플랩대관 예약도 받을게요
              </Checkbox>
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label htmlFor='appointment-date' className={classes.InputLabel}>
              일정
            </label>
            <InputDate
              attribute={{
                id: 'appointment-date',
                value: enteredDate,
                onChange: changeDateHandler,
              }}
            />
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap>
            <label
              htmlFor='appointment-time'
              className={`${classes.InputLabel} ${classes.hidden}`}
            >
              시간
            </label>
            <Select
              selectType='object'
              attribute={{
                id: 'appointment-time',
                value: enteredStartTime.optionVal,
                onChange: changeStartTimeHandler,
              }}
              options={timeOptions('start_time', enteredDate)}
            />
            <span style={{ margin: '0 -.3125rem' }}>~</span>
            <Select
              selectType='object'
              attribute={{
                value: enteredEndTime.optionVal,
                onChange: changeEndTimeHandler,
              }}
              options={timeOptions('end_time', enteredDate, enteredStartTime)}
            >
              <span className={classes['AppointmentForm__EndTime-Date']}>
                {new Date(enteredEndTime.optionVal).toLocaleDateString(
                  'ko-KR',
                  {
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                  }
                )}
              </span>
            </Select>
          </FormControlWrap>
        </div>
        <div className={classes['AppointmentForm__Row']}>
          <FormControlWrap classList={['space-between']}>
            <label htmlFor='appointment-repeat' className={classes.InputLabel}>
              반복
            </label>
            <ToggleBtn
              attribute={{
                id: 'appointment-repeat',
                onChange: changeIsRepeatHandler,
                checked: isRepeat,
              }}
            />
          </FormControlWrap>
        </div>
        <div
          className={`${classes['AppointmentForm__RowsWrap']} ${
            classes.toggle
          } ${isRepeat ? '' : classes.hidden}`}
        >
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap classList={['flex-end']}>
              <label className={`${classes.InputLabel} ${classes.hidden}`}>
                반복 일정 복제할 요일
              </label>
              {selectedDaysOptions}
            </FormControlWrap>
          </div>
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label className={`${classes.InputLabel} ${classes.hidden}`}>
                반복 종료일 옵션
              </label>
              <InputRadio
                attribute={{
                  id: 'end-date',
                  name: 'repeat-option',
                  onClick: changeSelectedRepeatOptHandler,
                  defaultChecked: true,
                }}
                classList={['limitedWidth45']}
              >
                종료일
              </InputRadio>
              <InputDate
                attribute={{
                  value: enteredEndDate,
                  onChange: changeEndDateHandler,
                  tabIndex: selectedRepeatOpt === 'end-date' ? '' : -1,
                }}
                classList={selectedRepeatOpt === 'end-date' ? [] : ['disabled']}
              />
            </FormControlWrap>
          </div>
        </div>
        {((enteredProductType !== '예약불가' &&
          enteredProductType !== '소셜매치') ||
          (enteredProductType === '소셜매치' &&
            enteredSocialStatus === 'social-standby' &&
            enteredPlabRentalOpt)) && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap>
              <label htmlFor='product-price' className={classes.InputLabel}>
                가격
              </label>
              <InputPrice
                attribute={{
                  id: 'product-price',
                  value: enteredPrice,
                  onChange: changePriceHandler,
                }}
              />
            </FormControlWrap>
          </div>
        )}
        {enteredProductType !== '예약가능' &&
          enteredProductType !== '예약불가' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label
                  htmlFor={
                    enteredProductType === '소셜매치' ? '' : 'consumer-name'
                  }
                  className={classes.InputLabel}
                >
                  대관자
                </label>
                <InputText
                  attribute={{
                    type: 'text',
                    id: 'consumer-name',
                    value:
                      enteredProductType === '소셜매치' &&
                      enteredSocialStatus === 'social-public'
                        ? '플랩풋볼'
                        : enteredProductType === '소셜매치' &&
                          enteredSocialStatus === 'social-standby'
                        ? null
                        : enteredName,
                    onChange: changeNameHandler,
                    placeholder: '이름',
                    tabIndex: enteredProductType === '소셜매치' ? -1 : '',
                  }}
                  classList={
                    enteredProductType === '소셜매치' ? ['disabled'] : []
                  }
                />
              </FormControlWrap>
            </div>
          )}
        {enteredProductType !== '소셜매치' &&
          enteredProductType !== '예약가능' &&
          enteredProductType !== '예약불가' && (
            <div className={classes['AppointmentForm__Row']}>
              <FormControlWrap>
                <label htmlFor='phone-number' className={classes.InputLabel}>
                  연락처
                </label>
                <InputText
                  attribute={{
                    type: 'text',
                    id: 'phone-number',
                    value: enteredPhoneNum,
                    onChange: changePhoneNumHandler,
                    placeholder: '연락처',
                  }}
                />
              </FormControlWrap>
            </div>
          )}
        {enteredProductType !== '예약가능' && (
          <div className={classes['AppointmentForm__Row']}>
            <FormControlWrap classList={['align--flex-start']}>
              <label
                htmlFor='note'
                className={`${classes.InputLabel} ${classes.textarea}`}
              >
                메모
              </label>
              <Textarea
                attribute={{
                  id: 'note',
                  value: enteredNote,
                  onChange: changeNoteHandler,
                  placeholder: '기타',
                }}
              />
            </FormControlWrap>
          </div>
        )}
        <div className={classes['AppointmentForm__Row']}>
          <Button attribute={{ type: 'submit' }}>확인</Button>
        </div>
      </form>
      {conflictedSchedulePopUp.isOpen && (
        <ConflictedSchedulePopUp
          onClose={conflictedPopUpHandler}
          time={{
            startTime: enteredStartTime.optionTxt,
            endTime: enteredEndTime.optionTxt,
          }}
          conflictedSchedule={conflictedSchedulePopUp.data}
        />
      )}
      {isLoading && <Loader />}
    </Modal>
  );
};

export default AppointmentForm;
