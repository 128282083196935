import TimeScaleLabel from './TimeScaleLabel';
import classes from './TimeScaleLayout.module.css';

const TimeScaleLayout = (props) => {
  const { startDayHour = 6, endDayHour = 30 } = props;

  const timeScaleLabelList = Array(endDayHour)
    .fill()
    .map((row, idx) => (
      <TimeScaleLabel
        key={idx}
        {...props}
        firstDayHour={idx < 24 ? idx : idx - 24}
      />
    ));

  return (
    <div className={classes.TimeScaleLayout}>
      {timeScaleLabelList.filter(
        (label, idx) => startDayHour <= idx && endDayHour >= idx
      )}
    </div>
  );
};

export default TimeScaleLayout;
