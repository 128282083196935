import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../contexts/auth-context';
import PenaltyCountContext from '../../../../contexts/penalty-count';
import NewPwdForm from '../../../Forms/NewPwdForm/NewPwdForm';
import classes from './UserSetting.module.css';

const UserSetting = (props) => {
  const authCtx = useContext(AuthContext);
  const PenaltyCountCxt = useContext(PenaltyCountContext);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const newPwdFormOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  useEffect(() => {
    PenaltyCountCxt.getAccountPenaltyCount();
  }, []);

  return (
    <>
      <div className={classes.UserSetting}>
        <div>{authCtx.userEmailId}</div>
        <button
          type='button'
          className={classes['UserSetting__Change-PWD']}
          onClick={newPwdFormOpenHandler}
        >
          비밀번호 변경
        </button>
      </div>
      <div className={classes.UserSetting}>
        <div>페널티</div>
        <div>{PenaltyCountCxt.accountPenaltyCount}개</div>
      </div>
      {isFormOpen && <NewPwdForm onClose={newPwdFormOpenHandler} />}
    </>
  );
};

export default UserSetting;
